import React from 'react';
import heading from '../../assets/img/heading.png';
import Form from 'react-bootstrap/Form';
import MailBanner from "../../components/Freemium/MailBanner";
import "./Freemium.css";

const FreemiumBanner = () => {

  // const [showReg, setShowReg] = useState(false);

  // const regFunc = () =>{
  //   if(!showReg){
  //     setShowReg(showReg);
  //   }
  // }
  return (
    <div>
     <div className="container-fluid">
      <div className="row">
        <div className="col-5 leftbanner d-flex align-items-center justify-content-cteenter">
          <div className="banner-main d-flex flex-column justify-content-center align-items-center">
            <img src={heading} alt="title" className="img-fluid w-50" />
            <p className="text-center w-75 mt-5 text-white">It is the ultimate solution for organizations looking to automate their Testcases, test suites and unlock the full potential of AI-driven testcases creation. With its intuitive interface, powerful capabilities, and unrivaled performance, Ignitho quality accelerator empowers businesses to deliver exceptional service.</p>
          </div>
        </div>

        <div className="col-7 rightBanner d-flex align-items-center justify-content-center">
          <Form className="w-50 bg-white rounded">
          
         <MailBanner/> 
         
          </Form>
          </div>
        </div>
        </div>
    </div>
  )
}

export default FreemiumBanner