import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Overview.css";
import { useState } from "react";
import { useEffect } from "react";
import { API } from "../../../../global";
import { useParams } from "react-router";
import {BiHash} from "react-icons/bi"
import {RiPercentLine} from "react-icons/ri"



let slidesToShow = 5;


const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
     {currentSlide !== 0 && (
  <div
    className={className}
    onClick={onClick}
    tabIndex={0}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick();
      }
    }}
    style={{ cursor: "pointer" }}
  >
    <span style={{ color: "blue", fontSize: "30px" }}>
      <i className="bi bi-chevron-left"></i>
    </span>
  </div>
)}

    </>
  );
};

const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  // console.log(props);
  return (
    <>
    {currentSlide !== slideCount - slidesToShow && (
  <div
    className={className}
    onClick={onClick}
    tabIndex={0}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onClick();
      }
    }}
    style={{ cursor: "pointer" }}
  >
    <span style={{ color: "blue", fontSize: "30px" }}>
      <i className="bi bi-chevron-right over-bi"></i>
    </span>
  </div>
)}

    </>
  );
};
const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 2,
  infinite: false,
  // slidesToScroll={ 3}
  responsive: [
    {
      breakpoint: 426,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 4,
        centerMode: false,
        slidesToScroll: 2,
      },
    },
  ],
};

function Overview() {

  // const navigate = useNavigate();
  const {context} = useParams();
  const [width, setWidth] = useState(window.innerWidth);
  const [ptc, setPTC] = useState(true);
  const [ftc, setFTC] = useState(true);
  const [nrt, setNRT] = useState(true);
  const [testsuiteData, setTestsuiteData] = useState({});
  // const [testcaseData, setTestcaseData] = useState({});
  // const [passed_tc, setPassed_tc] = useState(0);
  // const [failed_tc, setFailed_tc] = useState(0);
  // const [testsuite_logs,setTestsuite_logs] = useState([])

  console.log(testsuiteData);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  const project_id = localStorage.getItem("project-id");
 const application_id = localStorage.getItem("app_id")
 const testsuite_id = localStorage.getItem("suite_id")
 
 
  useEffect(() => {
    getTestsuiteLogs();
    // getTestcaseLogs()

    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
    // eslint-disable-next-line
  }, [context]);


  const getTestsuiteLogs = () =>{
    const period = localStorage.getItem('period')
    setTestsuiteData([])
    if(localStorage.getItem("suite_id")!=='null' && localStorage.getItem("suite_id")!==null){
      fetch(`${API}/tests/testsuites/report/runlog/dashboard/${testsuite_id}/${period}` ,{
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }).then((data) => data.json())
      .then((val)=>{
        console.log(val.result,"suite1");
        setTestsuiteData(val.result)
      } )
    }else{

      fetch(project_id && application_id === null ? `${API}/tests/testsuite/testlog/?project_id=${project_id}&&period=${period}` :`${API}/tests/testsuite/testlog/?project_id=${project_id}&&application_id=${application_id}&&period=${period}` ,{
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }).then((data) => data.json())
      .then((val)=>{
        console.log(val,"suite");
        setTestsuiteData(val.result)
      } )
    }
   
  }
  // const getTestcaseLogs = () =>{
  //   fetch(project_id && application_id === null ?`${API}/tests/testcases/runlog/?project_id=${project_id}` :`${API}/tests/testcases/runlog/?project_id=${project_id}&&application_id=${application_id}` ,{
  //     method: "GET",
  //     headers: {
  //       authorization: `${localStorage.getItem("access-key")}`,
  //     },
  //   }).then((data) => data.json())
  //   .then((val)=> setTestcaseData(val))
  // }
  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 3;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 4;
  } else {
    slidesToShow = 5;
  }

  // const tc_perc = ()=>{
  //   let pass_perc = (testcaseCount - failed_testcase)/100;
  // }
  return (
    <div
      style={{ paddingRight: "1%" }}
      className="carousel overflow-hidden Overview-carousel"
    >
      <div className="overview_header">
        <span>Overview</span>
        {/* <span>1 Aug 2022 - 8 Aug 2022</span> */}
      </div>
      <Slider {...carouselProperties}>
        <div className="slides slide_1">
          <h3>{testsuiteData.count ? testsuiteData.count : 0}</h3>
          <h4>TEST SUITES</h4>
        </div>

        <div className="slides slide_2">
          <h3>{testsuiteData.totalCase ? testsuiteData.totalCase : 0 }</h3>
          <h4>TEST CASES</h4>
        </div>
        <div className="slides slide_3">
           {/* TODO:  temporary fix*/}
          {ptc && <h3>{ testsuiteData.passedCaseCount ? testsuiteData.passedCaseCount : 0 }</h3>}
          { !ptc && <h3>{ testsuiteData.passedPercentage ? testsuiteData.passedPercentage : 0 }</h3>}
          {/* <h3 >{passed_testcase}</h3> */}
          <h4>
            PASSED TEST CASES{" "}
           
            <span
  style={{ cursor: 'pointer' }}
  onClick={() => setPTC(!ptc)}
  tabIndex={0}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      setPTC(!ptc);
    }
  }}
>
  {ptc ? <BiHash size={25} /> : <RiPercentLine size={25} />}
</span>

          </h4>
        </div>
        <div className="slides slide_4" >
          {ftc && <h3>{ testsuiteData.failedCaseCount ? testsuiteData.failedCaseCount : 0}</h3>}
          {!ftc && <h3>{ testsuiteData.failedPercentage ?testsuiteData.failedPercentage : 0}</h3>}
          {/* <h3>{ftc ? testsuiteData.failedCaseCount : testsuiteData.failedPercentage !== null ? Math.round(testsuiteData.failedPercentage) : 0}</h3> */}
          <h4>
            FAILED TEST CASES{" "}
            <span
  style={{ cursor: 'pointer' }}
  onClick={() => setFTC(!ftc)}
  tabIndex={0}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      setFTC(!ftc);
    }
  }}
>
  {ftc ? <BiHash size={25} /> : <RiPercentLine size={25} />}
</span>

          </h4>
        </div>
        <div className="slides slide_5" >
          {nrt && <h3>{ testsuiteData.noruncases ? testsuiteData.noruncases : 0}</h3>}
         { !nrt && <h3>{ testsuiteData.noRunCasesPercentage ? testsuiteData.noRunCasesPercentage : 0}</h3>}
          {/* <h3>{nrt ? testsuiteData.noruncases : testsuiteData.noRunCasesPercentage !== null ? Math.round(testsuiteData.noRunCasesPercentage) : 0 }</h3> */}
          {/* <h3>{norun}</h3> */}
          <h4>
            NO RUN TEST CASES{" "}
            <span
  style={{ cursor: 'pointer' }}
  onClick={() => setNRT(!nrt)}
  tabIndex={0}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      setNRT(!nrt);
    }
  }}
>
  {nrt ? <BiHash size={25} /> : <RiPercentLine size={25} />}
</span>

          </h4>
        </div>

        {/*  <div className="slides slide_6">
          <h3>50%</h3>
          <h4>AUTOMATION TEST COVERAGE</h4>
        </div>
        <div className="slides slide_7">
          <h3>40%</h3>
          <h4>AUTOMATION STABILITY</h4>
        </div>
        */}
      </Slider>
    </div>
  );
}

export default Overview;
