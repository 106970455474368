import React, { useEffect, useState } from "react";
import Header from "../../Layout/Navbar/Navbar";
import "./TestsuiteList.css";
import Table from "react-bootstrap/Table";
import report from "./../../../assets/Icons/Report.svg";
import EditTsButton from "../EditTestsuite/EditTestsuite";
import DeleteTsButton from "../DeleteTestsuite/DeleteTestsuite";
import CreateTsButton from "../CreateTestsuite/CreateTestsuite";
import ScheduleTestsuite from "../ScheduleTestSuite/ScheduleTestsuite";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import moment from "moment";
import { API } from "../../../global";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import RunTestsuite from "../Testsuite Execution/Run/Run";
import { Oval } from "react-loader-spinner";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { SlClose } from "react-icons/sl";
import filter from "./../../../assets/Icons/Filter.svg";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { toast } from "react-toastify";

export default function TestsuiteList() {
  const navigate = useNavigate();
  const [testsuitelogs, setTestsuitelogs] = useState([]);
  const [searchList, setSearchlist] = useState([]);

  const [runvalue, setRunvalue] = useState("");
  const [exedate, setExedate] = useState("");
  const [searchApp, setSearchApp] = useState("");
  const [unid, setUnid] = useState("");
  const [suite, setSuite] = useState([]);
  const [app_selected, setApp_selected] = useState("");
  const [applist, setApplist] = useState([]);
  const [dropSearchlist, setDropSearchlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("asc");
  const [check, setCheck] = useState(false);
  // const [checkAll, setCheckAll] = useState(false);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const app_name = localStorage.getItem("app-id");
  const project_id = localStorage.getItem("project-id");
  const app_id = localStorage.getItem("app_id");

  const get_project_id = () => {
    if (localStorage.getItem("project-id")) {
      navigate(`/qe/testsuite/pr/${project_id}`);
    }
  };
  const theme = createTheme({
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "#5D3B5E",
              color: "white",
              "&:hover": {
                backgroundColor: "#805382 !important",
              },
            },
            "&:not(.Mui-selected):hover": {
              backgroundColor: "#805382",
              color: "white",
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    get_project_id();
    getApplication();
    getTestsuitesByProjectId(app_id, true);
    getAllTestsuites();
    // eslint-disable-next-line
  }, [project_id]);

  // let logs = [];
  // let list = [];
  const getTestsuitesByProjectId = (appid, load, count) => {
    // list = [];
    // logs = [];
    // setTestsuitelogs([]);

    setLoading(load);
    setApp_selected("");
    fetch(
      project_id && appid == null
        ? `${API}/tests/testsuite/application/?project_id=${project_id}`
        : project_id && appid === undefined
        ? `${API}/tests/testsuite/application/?project_id=${project_id}`
        : `${API}/tests/testsuite/application/?project_id=${project_id}&application_id=${appid}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    ) //promise
      .then((data) => data.json()) //Response Object
      .then((ts) => {
        setSuite(ts.result.data);
        // setTestsuitelogs(ts.result.data);        
        setSearchlist(ts.result.data);
        setPage(Math.ceil(ts.result.data.length / 10));
        const suiteData = ts.result.data.filter(
          (test) =>
            test.last_run_status === "Pending" ||
            test.last_run_status === "queued" ||
            test.last_run_status === "in progress"
        );
        getTestsuiteLogs(suiteData, appid, count);
        // setTestsuitelist(ts.data);
        // list = [...ts.data];
      });
    // .then(() => getTestsuiteLogs());
  };

  const getTestsuiteLogs = (suiteList, appid, count) => {
    // let testsuite = suiteList.filter(h => h.last_run_status === 'Pending')
    // setSuite(testsuite)
    if (count === undefined) count = currentPage;
    let startCount = 0;
    let endCount = 0;
    let firstValue = count;
    if (count === 1) {
      firstValue = undefined;
    } else {
      startCount = (count - 1) * 10;
      endCount = startCount + 10;
    }
    if (project_id && appid === null) {
      fetch(`${API}/tests/testsuite/testlog/?project_id=${project_id}`, {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }) //promise
        .then((data) => data.json()) //Response Object
        .then((ts) => {
          const sorted = ts.result.data.sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
          );
          const unique = sorted.filter(
            (obj, index) =>
              ts.result.data.findIndex(
                (item) => item.testsuite_id === obj.testsuite_id
              ) !== index
          );
          console.log(unique, "fgf");

          const unique2 = sorted.filter((obj, index) => {
            return (
              index ===
              sorted.findIndex((o) => obj.testsuite_id === o.testsuite_id)
            );
          });
          let hhh = [...suiteList, ...unique2];

          // const testData = hhh.sort((a,b)=> a.testsuite_id - b.testsuite_id)
          const unique3 = hhh.filter((obj, index) => {
            return (
              index ===
              hhh.findIndex((o) => obj.testsuite_id === o.testsuite_id)
            );
          });

          const testData = unique3.sort(
            (a, b) => a.testsuite_id - b.testsuite_id
          );
          const unique4 = testData.filter((obj, index) => {
            return (
              index ===
              testData.findIndex((o) => obj.testsuite_id === o.testsuite_id)
            );
          });
          let paginationArr = [];
          // setTestsuitelogs(unique4)
          // setSearchlist(unique4)
          setPage(Math.ceil(unique4.length / 10));
          paginationArr = [...unique4];
          paginationArr = paginationArr.map((a, i)=>({
            ...a,
            id: i+1,
            count:a.testcase_count ? a.testcase_count : a.test_cases.length
                    }))
          if (firstValue) {
            setTestsuitelogs(paginationArr.slice(startCount, endCount));
            setSearchlist(paginationArr);
          } else {
            setTestsuitelogs(paginationArr.slice(0, 10));
            setSearchlist(paginationArr);
          }
          // setSuitelog(ts.data)
        })

        .then(() => setLoading(false));
      // console.log(logs);
    } else if (project_id && appid === undefined) {
      fetch(`${API}/tests/testsuite/testlog/?project_id=${project_id}`, {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }) //promise
        .then((data) => data.json()) //Response Object
        .then((ts) => {
          const sorted = ts.result.data.sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
          );
          const unique = sorted.filter(
            (obj, index) =>
              ts.result.data.findIndex(
                (item) => item.testsuite_id === obj.testsuite_id
              ) !== index
          );
          console.log(unique, "fgf");

          // let dummy = [...unique, ...ts.data]
          // const duplicates = ts.data.filter((item, index) => index != ts.data.indexOf(item));
          // console.log(dummy, 'dummy');
          // console.log(duplicates,'jgkjg');
          const unique2 = sorted.filter((obj, index) => {
            return (
              index ===
              sorted.findIndex((o) => obj.testsuite_id === o.testsuite_id)
            );
          });
          let hhh = [...suiteList, ...unique2];

          // const testData = hhh.sort((a,b)=> a.testsuite_id - b.testsuite_id)
          const unique3 = hhh.filter((obj, index) => {
            return (
              index ===
              hhh.findIndex((o) => obj.testsuite_id === o.testsuite_id)
            );
          });

          const testData = unique3.sort(
            (a, b) => a.testsuite_id - b.testsuite_id
          );
          const unique4 = testData.filter((obj, index) => {
            return (
              index ===
              testData.findIndex((o) => obj.testsuite_id === o.testsuite_id)
            );
          });

          setTestsuitelogs(unique4);
          setSearchlist(unique4);
          setPage(Math.ceil(unique4.length / 10));
          let paginationArr = [];
          // setTestsuitelogs(unique4)
          // setSearchlist(unique4)
          setPage(Math.ceil(unique4.length / 10));
          paginationArr = [...unique4];
          paginationArr = paginationArr.map((a, i)=>({
            ...a,
            id: i+1,
            count:a.testcase_count ? a.testcase_count : a.test_cases.length

          }))
          if (firstValue) {
            setTestsuitelogs(paginationArr.slice(startCount, endCount));
            setSearchlist(paginationArr);
          } else {
            setTestsuitelogs(paginationArr.slice(0, 10));
            setSearchlist(paginationArr);
          }
          // setSuitelog(ts.data)
        })

        .then(() => setLoading(false));
      // console.log(logs);
    } else if (project_id && appid) {
      fetch(
        `${API}/tests/testsuite/testlog/?project_id=${project_id}&application_id=${appid}`,
        {
          method: "GET",
          headers: {
            authorization: `${localStorage.getItem("access-key")}`,
          },
        }
      ) //promise
        .then((data) => data.json()) //Response Object
        .then((ts) => {
          const sorted = ts.result.data.sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
          );
          const unique = sorted.filter((obj, index) => {
            return (
              ts.result.data.findIndex(
                (item) => item.testsuite_id === obj.testsuite_id
              ) === index
            );
          });
          console.log(unique);
          const unique2 = sorted.filter((obj, index) => {
            return (
              index ===
              sorted.findIndex((o) => obj.testsuite_id === o.testsuite_id)
            );
          });
          // console.log(unique2,'uniq2');
          let hhh = [...suiteList, ...unique2];
          // console.log(hhh,"uni34")
          const unique3 = hhh.filter((obj, index) => {
            return (
              index ===
              hhh.findIndex((o) => obj.testsuite_id === o.testsuite_id)
            );
          });
          // console.log(unique3,"unique3")
          const testData = unique3.sort(
            (a, b) => a.testsuite_id - b.testsuite_id
          );
          const unique4 = testData.filter((obj, index) => {
            return (
              index ===
              testData.findIndex((o) => obj.testsuite_id === o.testsuite_id)
            );
          });
          console.log(unique4, "un4");
          // setTestsuitelogs(unique4)
          // setSearchlist(unique4)
          setPage(Math.ceil(unique4.length / 10));
          let paginationArr = [];
          // setTestsuitelogs(unique4)
          // setSearchlist(unique4)
          setPage(Math.ceil(unique4.length / 10));
          paginationArr = [...unique4];
          paginationArr = paginationArr.map((a, i)=>({
            ...a,
            id: i+1,
            count:a.testcase_count ? a.testcase_count : a.test_cases.length

          }))
          if (firstValue) {
            setTestsuitelogs(paginationArr.slice(startCount, endCount));
            setSearchlist(paginationArr);
          } else {
            setTestsuitelogs(paginationArr.slice(0, 10));
            setSearchlist(paginationArr);
          }
          // setSuitelog(ts.data)
        })

        .then(() => setLoading(false));
      // console.log(logs);
    }
  };
  const handleChange = (event, value) => {
    // getProject(value)
    getTestsuitesByProjectId(app_id, true, value);
    setCurrentPage(value);
    // setPage(value);
  };
  //to get application in the dropdown

  const getApplication = () => {
    setApplist([]);
    fetch(`${API}/admin/application/${localStorage.getItem("project-id")}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {
        let newData = [...app.result.data];
        newData.unshift({ name: "All" });

        newData.sort((a, b) => {
          if (a.name === "All") {
            return -1;
          }
          if (b.name === "All") {
            return 1;
          }
          return a.name.localeCompare(b.name);
        });

        app.result.data.length > 0
          ? setDropSearchlist(newData)
          : setDropSearchlist([]);
        app.result.data.length > 0 ? setApplist(newData) : setApplist([]);
      });
  };
  const getAllTestsuites = () => {
    fetch(`${API}/tests/testsuite/all`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }) //promise
      .then((data) => data.json()) //Response Object
      .then((ts) => {
        ts.result.data.length > 0
          ? localStorage.setItem(
              "tsID",
              ts.result.data[ts.result.data.length - 1].testsuite_id
            )
          : localStorage.setItem("tsID", 0);
      });
  };

  const status = (val, exec_date) => {
    if (val === "Pending") {
      //  setRundata(true);
      setTimeout(() => {
        //   setRundata(false);
      }, 60000);
      setExedate(exec_date);
    } else {
      setRunvalue(val);
      setExedate(exec_date);
      setInterval(() => {
        //    setRundata(false);
      }, 7500);
    }
  };

  const getId = (test) => {
    let testsuiteId = test.test_suite_id ? test.test_suite_id._id : test._id;

    setUnid(testsuiteId);
    localStorage.setItem("testsuite-id", testsuiteId);
    setRunvalue("");
  };
  const checkAllBox = () => {
    let allCheck = document.getElementById("allcheck").checked;
    let temp = [...testsuitelogs];
    
    temp.forEach((d) => {
      d.checked = allCheck;
    });
  
    // setCheck(!check); // Uncomment if you need to toggle the check state
    setTestsuitelogs(temp);
  };
  
  const singleCheck = (a) => {
    // setCheckAll(false);
    let temp = [...testsuitelogs];
    temp = temp.map((d) => {
      if (d._id === a) {
        d.checked = !check;
      }
      return d;
    });
    if (temp.filter((d) => d.checked === true).length === temp.length) {
      // setCheckAll(true);
    }
    setCheck(!check);
    setTestsuitelogs(temp);
  };

  const bulkRun =()=>{
    const filterSelectedValue = testsuitelogs.filter(d=>d.checked===true);
    let filterSelectedValueId = filterSelectedValue.map(a=> a.test_suite_id ? a.test_suite_id._id : a._id)
   console.log(filterSelectedValue,'iddd');
   console.log(filterSelectedValueId,'iddd');
    fetch(`${API}/tests/testsuite/bulk_run`, {
      method: "POST",
      body: JSON.stringify(filterSelectedValueId),
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then((res) => res.json())
    .then((b)=>{
      if(b.success){
        toast.success('Run Start Successfully')
        window.location.reload();
      }else{
        toast.error(b.info.msg)
      }
    })
  }

  const getFilter = (value) => {
    let addTstoIds = searchList.map((d) => {
      return {
        test_suite_name: d.test_suite_id ? d.test_suite_id.name : d.name,
        ...d,
        listId: `TS${d.id}`,
      };
    });  
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue.length === 0) { 
      setTestsuitelogs(searchList); 
    } else {
      const filteredData = addTstoIds.filter((item) => {
        return Object.keys(item).some((key) =>
          item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });        

      setTestsuitelogs(filteredData);
    }
  }; 
  //Sorting func
           
  const sorting = (order) => {
    if (sort === "asc") {
      const sorted = [...testsuitelogs].sort((a, b) =>
        a[order] > b[order] ? 1 : -1
      );
      setTestsuitelogs(sorted);
      setSort("dsc");
    }
    if (sort === "dsc") {
      const sorted = [...testsuitelogs].sort((a, b) =>
        a[order] < b[order] ? 1 : -1
      );
      setTestsuitelogs(sorted);

      setSort("asc");
    }
  };
  const handleDropChange = (e) => {
    setSearchApp(e);
    console.log(e);
    if (e.length === 0) {
      setApplist(dropSearchlist);
    } else {
      let fillteredApp = dropSearchlist.filter((d) =>
        d.name.toLowerCase().includes(e.toLowerCase())
      );
      setApplist(fillteredApp);
    }
  };
  //   const handleChange = (event, value) => {
  //     // getProject(value)
  //     setCurrentPage(value)
  //     // setPage(value);
  // };

  let testlist;

  const [expand, setExpand] = useState(false);

  let top_green = (
    <Container fluid>
      <Row className="testsuite-test-row">
        <Col sm={5}>
          <div className="d-flex first-head justify-content-start">
            Test Suites
          </div>
        </Col>

        <Col sm={7} className="qe_testsuite">
          <div className="d-flex justify-content-end TS-test-btns">
            <div className="btn-group">
              <label className="TS-bulk position-absolute">Action</label>
              <button
                type="button"
                className="TS-bulk-btn me-3 dropdown-toggle"
                data-bs-toggle="dropdown"
                size="sm"
                aria-expanded="false"
              >
                Bulk Action
              </button>
              <ul className="dropdown-menu run-drop text-center overflow-hidden">
                <li
                  tabIndex={0}
                  className="dropdown-item"
                  style={{ fontSize: "14px" }}
                  onClick={bulkRun}
                  onKeyDown={(e) => {  
                    if (e.key === 'Enter' || e.key === ' ') {
                      bulkRun();  
                    }
                  }}
                >
                  Run
                </li>
                {/* <li
                  tabIndex={0}
                  className="dropdown-item"
                  style={{ fontSize: "14px" }}
                >
                  Publish
                </li> */}
              </ul>
            </div>
            {/* application dropdown */}

            <div className="btn-group">
              <label className="TS-App position-absolute ">Application</label>
              <div className="TS-bulk-btn me-3">
                <button
                  type="button"
                  className="TS-app-btn"
                  data-bs-toggle="dropdown"
                  size="sm"
                  aria-expanded="false"
                >
                  {app_selected && app_selected.length > 10
                    ? `${app_selected.slice(0, 10)}...`
                    : app_selected && app_selected.length <= 10
                    ? app_selected
                    : app_name && app_name.length > 10
                    ? `${app_name.slice(0, 10)}...`
                    : app_name && app_name.length <= 10
                    ? app_name
                    : "- - Select - -"}
                </button>
                <span className="dropdown-toggle"></span>

                <ul className="dropdown-menu text-center">
                  <li>
                    <input
                      type="text"
                      value={searchApp}
                      onChange={(e) => handleDropChange(e.target.value)}
                    />
                  </li>
                  {applist.length === 0 ? (
                    <li className="dropdown-item dScroll">
                      No Application found
                    </li>
                  ) : (
                    applist.map((app, i) => {
                      return (
                        <li
  key={i}
  className="dropdown-item"
  tabIndex={0}
  onClick={() => {
    getTestsuitesByProjectId(app._id, true);
    setApp_selected(app.name);
    localStorage.setItem("app-id", app.name);
    localStorage.setItem("app_id", app._id);
  }}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      // Trigger the click handler
      getTestsuitesByProjectId(app._id, true);
      setApp_selected(app.name);
      localStorage.setItem("app-id", app.name);
      localStorage.setItem("app_id", app._id);
    }
  }}
>
  {app.name}
</li>

                      );
                    })
                  )}
                </ul>
              </div>
            </div>
            {/* application dropdown end */}
            <Dropdown>
              <CreateTsButton
                getTestsuites={getTestsuitesByProjectId}
                className="test-suite-create-btn"
              />
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Container>
  );

  if (!project_id && suite.length === 0) {
    testlist = (
      <>
        {/* {top_green} */}
        <div
          style={{
            marginTop: "20%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            {" "}
            <AiOutlineInfoCircle size="30px" color="#9F9F9F" />
          </div>
          <h6 style={{ color: "#9F9F9F" }}>Please Select a Project</h6>
        </div>
      </>
    );
  } else if (project_id && loading) {
    testlist = (
      <div className="tc-loader">
        <Oval
          height={80}
          width={1800}
          color="#805382"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#F0E3F1#AB78AE"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  } else if (testsuitelogs.length === 0) {
    testlist = (
      <>
        {top_green}
        <div
          style={{
            marginTop: "15%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            {" "}
            <SlClose size="30px" color="#9F9F9F" />
          </div>
          <h6 style={{ color: "#9F9F9F" }}>No Testsuite found</h6>
        </div>
      </>
    );
  } else {
    testlist = (
      <>
        {top_green}
        <div className="testsuite_table_container">
          <Table className="testsuite-table">
            <thead
              style={{
                backgroundColor: "#E6C6E840",

                color: "#805382",
              }}
              className="align-middle"
            >
              <tr>
                <th
        
                  className="align-middle"
                >
                  <input
                    type="checkbox"
                    id="allcheck"
                    onChange={() => checkAllBox()}
                    // checked={checkAll}
                    style={{ margin: "6px", zoom: "1" }}
                  />
                  ID
                  <span
                    onClick={() => {
                      sorting('id'); setExpand(!expand);
                    }}
                    onKeyDown={(e) => { 
                      if (e.key === 'Enter' || e.key === ' ') {
                        sorting('id'); 
                        setExpand(!expand);
                      }
                    }}
                  >
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                </th>
                <th
                  // style={{
                  //   borderRight: "2px solid #805382",
                  //   fontSize: "14px",
                  //   width: "13%",
                  // }}
                  className="align-middle"
                >
                  Name
                  <span
                    onClick={() => {
                      sorting('name'); setExpand(!expand);
                    }}
                    onKeyDown={(e) => { 
                      if (e.key === 'Enter' || e.key === ' ') {
                        sorting('name'); 
                        setExpand(!expand);
                      }
                    }}
                  >
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                </th>
                <th     //120000
                  // style={{
                  //   borderRight: "2px solid #805382",
                  //   width: "13%",
                  //   fontSize: "14px",
                  // }}
                  className="align-middle"
                >
                  No of Test Cases
                  <span
                    onClick={() => {
                      sorting('count'); setExpand(!expand);
                    }}
                    onKeyDown={(e) => {       // Handles keyboard events
                      if (e.key === 'Enter' || e.key === ' ') {
                        sorting('count'); 
                        setExpand(!expand);
                      }
                    }}
                  >
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                </th>
                <th
                  // style={{
                  //   borderRight: "2px solid #805382",
                  //   fontSize: "14px",
                  //   width: "13%",
                  // }}
                  className="align-middle"
                >
                  Last Run Status
                </th>
                <th
                  // style={{
                  //   borderRight: "2px solid #805382",
                  //   fontSize: "14px",
                  //   width: "13%",
                  // }}
                  className="align-middle"
                >
                  Version

                </th>

                <th
                  style={{
                    borderRight: "2px solid #805382",
                    fontSize: "14px",
                    width: "13%",
                  }}
                  className="align-middle"
                >
                  Last Executed
                  <span
                    onClick={() => {
                      sorting('updated_at'); setExpand(!expand);
                    }}
                    onKeyDown={(e) => {       // Handles keyboard events
                      if (e.key === 'Enter' || e.key === ' ') {
                        sorting('updated_at'); 
                        setExpand(!expand);
                      }
                    }}
                  >
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                </th>
                <th
                 style={{ width: "23%" }}
                 >
                  <span >Actions</span>
                  <img src={filter} className="TC-filter" alt="filter icon" />
                </th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {testsuitelogs.map((testsuite, i) => {
                // const n = i + 1;
                // const num = `TS-${n}`;
                //const testsuite.testcase_count = testsuite.test_cases.length;

                return (
                  <>
                    {(testsuite.last_run_status === "Pending" ||
                      testsuite.last_run_status === "Passed" ||
                      testsuite.last_run_status === "failed" ||
                      testsuite.last_run_status === "queued" ||
                      testsuite.last_run_status === "in progress" ||
                      testsuite.testcase_count > 0) && (
                      <tr key={i}>
                        <td>
                          <input
                            type="checkbox"
                            onChange={() => singleCheck(testsuite._id)}
                            checked={testsuite.checked}
                            style={{ margin: "6px", zoom: "1" }}
                          />
                          {`TS${testsuite.id}`}
                        </td>
                        <td>
                          {testsuite.testcase_count
                            ? testsuite.test_suite_id.name
                            : testsuite.name}
                        </td>
                        <td>
                          {testsuite.testcase_count
                            ? testsuite.testcase_count
                            : testsuite.test_cases.length}
                        </td>
                        <td
                          style={{
                            color:
                              testsuite.last_run_status === "passed" ||
                              (runvalue === "passed" &&
                                testsuite._id === unid) ||
                              (runvalue === "failed" && testsuite._id === unid)
                                ? "#7EB965"
                                : testsuite.last_run_status === "failed"
                                ? "#DC3545"
                                : "#eab676",
                            fontWeight: "bold",
                          }}
                        >
                          {testsuite.last_run_status === "Pending" ||
                          testsuite.last_run_status === "queued" ||
                          testsuite.last_run_status === "in progress" ? (
                            testsuite.last_run_status
                          ) : (
                            <>
                              <span>{testsuite.last_run_status}</span>

                              <div className="d-flex px-4">
                                <Tippy
                                  placement="top"
                                  theme="light"
                                  className="tippy-tooltip"
                                  content={
                                    testsuite.passed_test_cases !== undefined
                                      ? `Passed (${Math.round(
                                          (testsuite.passed_test_cases.length /
                                            testsuite.testcase_count) *
                                            100 
                                        )}%)`
                                      : "0%"
                                  } 
                                >
                                  <div
                                    className="value"
                                    style={{
                                      backgroundColor: "#7EB965",
                                      height: "16px",
                                      width:
                                        testsuite.passed_test_cases !==
                                        undefined
                                          ? (testsuite.passed_test_cases
                                              .length /
                                              testsuite.testcase_count) *
                                              100 +
                                            "%"
                                          : 0 + "%",
                                    }}
                                    key={i}
                                  >
                                    <span style={{ display: "none" }}>
                                      {exedate}
                                    </span>
                                  </div>
                                </Tippy>
                                <Tippy
                                  placement="top"
                                  theme="light"
                                  className="tippy-tooltip"
                                  content={
                                    testsuite.failed_test_cases !== undefined
                                      ? `Failed (${Math.round(
                                          (testsuite.failed_test_cases.length /
                                            testsuite.testcase_count) *
                                            100
                                        )}%)`
                                      : "0%"
                                  }
                                >
                                  <div
                                    className="value"
                                    style={{
                                      backgroundColor: "#DC3545",
                                      height: "16px",
                                      width:
                                        testsuite.failed_test_cases !==
                                        undefined
                                          ? (testsuite.failed_test_cases
                                              .length /
                                              testsuite.testcase_count) *
                                              100 +
                                            "%"
                                          : 0 + " %",
                                    }}
                                    key={i}
                                  ></div>
                                </Tippy>
                                <Tippy
                                  placement="top"
                                  theme="light"
                                  className="tippy-tooltip"
                                  content={
                                    testsuite.passed_test_cases !== undefined &&
                                    `No Run (${Math.round(
                                      ((testsuite.testcase_count -
                                        (testsuite.passed_test_cases.length +
                                          testsuite.failed_test_cases.length)) /
                                        testsuite.testcase_count) *
                                        100
                                    )}%)`
                                  }
                                >
                                  <div
                                    className="value"
                                    style={{
                                      backgroundColor: "#CCCCCC",
                                      height: "16px",

                                      width:
                                        testsuite.passed_test_cases !==
                                          undefined &&
                                        ((testsuite.testcase_count -
                                          (testsuite.passed_test_cases.length +
                                            testsuite.failed_test_cases
                                              .length)) /
                                          testsuite.testcase_count) *
                                          100 +
                                          "%",
                                    }}
                                    key={i}
                                  ></div>
                                </Tippy>
                              </div>
                            </>
                          )}
                        </td>
                        <td>v1</td>
                        {/* <td>{testsuite.updated_by.Ad_username}</td> */}
                        <td style={{ width: "200px" }}>
                          {testsuite.last_run_status === "Pending" ||
                          testsuite.last_run_status === "queued" ||
                          testsuite.last_run_status === "in progress"
                            ? "------"
                            : moment(testsuite.updated_at).format(
                                "D MMM h:mm a"
                              )}
                        </td>
                        <td>
                          <div className="TS-action-icons justift-content-center">
                            <ScheduleTestsuite
                              id={testsuite._id}
                              status={status}
                              getTestsuites={getTestsuitesByProjectId}
                            />
                            <Tippy
                              placement="top"
                              theme="light"
                              content={
                                testsuite.last_run_status === "Pending" ||
                                testsuite.last_run_status === "queued" ||
                                testsuite.last_run_status === "in progress"
                                  ? "Please Execute the Test suite"
                                  : "Report"
                              }
                              className="tippy-tooltip"
                            >
                             <img
  src={report}
  style={{ cursor: "pointer" }}
  alt="report icon"
  onClick={() =>
    navigate(`/qe/testsuite/reportTable/${testsuite.test_suite_id._id}`)
  }
  tabIndex={0}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      navigate(`/qe/testsuite/reportTable/${testsuite.test_suite_id._id}`);
    }
  }}
/>

                            </Tippy>
                            <button
                              style={{ background: "none", border: "none" }}
                              onClick={() => getId(testsuite)}
                            >
                              <RunTestsuite
                                id={
                                  testsuite.test_suite_id
                                    ? testsuite.test_suite_id._id
                                    : testsuite._id
                                }
                                status={status}
                                getTestsuites={getTestsuitesByProjectId}
                              />
                            </button>
                            <span
                            // onClick={() => editTestsuite(testsuite)}
                            >
                              <EditTsButton editTest={testsuite} />
                            </span>

                            <DeleteTsButton
                              id={
                                testsuite.test_suite_id
                                  ? testsuite.test_suite_id._id
                                  : testsuite._id
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }

  return (
    <div>
      <Header testcaselist={getFilter} />

      <div>
        {/*Here start adding the test suite drop options */}

        {testlist}
      </div>
      <div className="page-head fixed-bottom">
        <ThemeProvider theme={theme}>
          <Pagination
            count={page}
            page={currentPage}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
          />
        </ThemeProvider>
      </div>
      {/* <Paginate /> */}
    </div>
  );
}
