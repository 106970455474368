import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "./components/SignIn/SignIn";
import TestcaseList from "./components/TestCase/TestcaseList/TestcaseList";
import CreateTestcase from "./components/TestCase/CreateTestcase/CreateTestcase";
import GenerateTestcase from "./components/TestCase/GenerateTestcase/GenerateTestcase";
import EditTestcase from "./components/TestCase/EditTestcase/EditTestcase";
import CloneTestcase from "./components/TestCase/CloneTestcase/CloneTestcase";
import TestsuiteList from "./components/TestSuite/TestsuiteList/TestsuiteList";
import DashboardQE from "./components/Dashboard/DashboardQE/Dashboard";
import ProjectList from "./components/Admin/Project/ProjectList/ProjectList";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApplicationList from "./components/Admin/Application/ApplicationList/ApplicationList";
import DashboardAdmin from "./components/Dashboard/DashboardAdmin/DashboardAdmin";
import ReportTable from "./components/TestSuite/Report/ReportTable";
import Report from "./components/Reports/TestsuiteReport/ReportTable/ReportTable";
import TestReport from "./components/DashboardComponents/TestReport/TestReport";
import Schedule from "./components/Schedule/Schedule";
//import EditSchedule from "./components/Schedule/EditSchedule/EditSchedule";
import StakeHolderList from "./components/Admin/Stakeholder/Stakeholder List/StakeHolderList";
import TestcaseReport from "./components/TestCase/ExecutionReport/Report";
import TsReport from "./components/Reports/TestcaseReport/TestcaseReport";
import UserList from "./components/Admin/Users/User List/UserList";
import FreemiumLogin from "./components/Freemium/FreemiumLogin";
// import FreemiumRegister from "./components/Freemium/FreemiumRegister";
import FreemiumBanner from "./components/Freemium/FreemiumBanner";
import FreemiumLicense from "./components/Freemium/FreemiumLicense";
// import MailBanner from "./components/Freemium/MailBanner";
import FreemiumRegister from "./components/Freemium/FreemiumRegister";
import FreemiumReset from "./components/Freemium/FreemiumReset"; 


// import AuthVerify from "./AuthVerify";
// import { useNavigate } from "react-router";

function App() {
  const token = localStorage.getItem("access-key");
  // const role = localStorage.getItem("role-name");
  const project_id = localStorage.getItem("project-id");


  return (
    <div className="App">
      <Router>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeButton={false}
          ltr={true}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
          theme="light"
          limit={1}
        />
        <Routes>
          {/* <Route
            exact
            path="/"
            element={!token ? <FreemiumLogin /> : role === 'Quality Engineer' ? <DashboardQE /> : <DashboardAdmin />}
          /> */}
          {[
            "/qe/dashboard",
            "/qe/dashboard/pr/:context",
            "/qe/dashboard/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <DashboardQE /> : <FreemiumLogin />}
              />
            );
          })}

          {/* <Route  
            path="/qe/testcase/:id?"
            element={token ? <TestcaseList /> : <SignIn />}
          /> */}
          {/* <Route path="/qe/testcase">
            <Route path=":id" element={token ? <TestcaseList /> : <SignIn />} />
            <Route path="" element={token ? <TestcaseList /> : <SignIn />} />
          </Route> */}
          {/* <Route
            exact
            path={[
              "/qe/testcase",
              "/qe/testcase/:context",
              "/qe/testcase/:context/:var",
            ]}
            element={token ? <TestcaseList /> : <SignIn />}
          /> */}
          {[
            "/qe/testcase",
            "/qe/testcase/pr/:context",
            "/qe/testcase/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token && <TestcaseList />}
              />
            );
          })}
          <Route
            path="/qe/testcase/create-testcase"
            element={token ? <CreateTestcase /> : <FreemiumLogin />}
          />
          <Route
            path="/qe/testcase/generate-testcase"
            element={token ? <GenerateTestcase /> : <FreemiumLogin />}
          />
          <Route
            path="/qe/testcase/edit-testcase/:id"
            element={token ? <EditTestcase /> : <FreemiumLogin />}
          />
          <Route
            path="/qe/testcase/clone-testcase/:id"
            element={token ? <CloneTestcase /> : <FreemiumLogin />}
          />
          <Route
            path={project_id ? `/qe/testsuite/pr/${project_id}` : `/qe/dashboard`}
            element={token ? <TestsuiteList /> : <FreemiumLogin />}
          />
          {[
            "/qe/testsuite",
            "/qe/testsuite/pr/:context",
            "/qe/testsuite/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <TestsuiteList /> : <FreemiumLogin />}
              />
            );
          })}

          {[
            "/admin/stakeholder",
            "/admin/stakeholder/pr/:context",
            "/admin/stakeholder/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <StakeHolderList /> : <FreemiumLogin />}
              />
            );
          })}

          {[
            "/admin/users",
            "/admin/users/pr/:context",
            "/admin/users/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <UserList /> : <FreemiumLogin />}
              />
            );
          })}

          <Route
            path="/admin/project"
            element={token ? <ProjectList /> : <FreemiumLogin />}
          />
          {[
            "/admin/application",
            "/admin/application/pr/:context",
            "/admin/application/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <ApplicationList /> : <FreemiumLogin />}
              />
            );
          })}

          {[
            "/admin/dashboard",
            "/admin/dashboard/pr/:context",
            "/admin/dashboard/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token && <DashboardAdmin /> }
              />
            );
          })}
          <Route
            path="/qe/testsuite/reportTable/:id"
            element={token ? <ReportTable /> : <FreemiumLogin />}
          />
          <Route
            path="/qe/testReport/:id"
            element={token ? <TestReport /> : <FreemiumLogin />}
          />

          {[
            "/qe/schedule",
            "/qe/schedule/pr/:context",
            "/qe/schedule/pr/:context/:apply",
          ].map((path, index) => {
            return (
              <Route
                path={path}
                element={token ? <Schedule /> : <FreemiumLogin />}
              />
            );
          })}
          <Route
            path="/qe/testsuite/report"
            element={token ? <ReportTable /> : <FreemiumLogin />}
          />
          <Route
            path="/qe/testcase/report/:testId"
            element={token ? <TestcaseReport /> : <FreemiumLogin />}
          />
          {[
            "/qe/testcase/reports",
            "/qe/testcase/reports/:proId",
          ].map((path) => {
            return (
              <Route
                path={path}
                element={token ? <TsReport /> : <FreemiumLogin />}
              />
            );
          })}

          {[
            "/qe/testsuite/report/table",
            "/qe/testsuite/report/table/:proId",
          ].map((path) => {
            return (
              <Route
                path={path}
                element={token ? <Report /> : <FreemiumLogin />}
              />
            );
          })}
         <Route path='/login' element={<FreemiumLogin/>}/>
         <Route path='/ms/login' element={<SignIn/>}/>

         <Route path='/register' element={<FreemiumRegister/>}/>  

         <Route path='/pricing' element={<FreemiumLicense/>}/>   

         <Route path='/verify' element={<FreemiumBanner/>}/> 
        <Route path='/verify-account/:auth' element={ <FreemiumReset/> } /> 
         {/* <Route path='reset' element={ <FreemiumReset /> }/> */}
          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
