import { useState } from "react";
import "./AdminTopgreen.css";
import { Button, Form, Nav } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
// import 'bootstrap-daterangepicker/daterangepicker.css';

function AdminTopgreen() {
  const [period_selected,setPeriod_selected] = useState("All Time");
  const [smShow, setSmShow] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

           
  const handleSelectPeriod =(a, e)=>{
    
    localStorage.setItem('period',e.target.getAttribute('value'));
    localStorage.setItem('period_name', e.target.textContent );
    setPeriod_selected(e.target.textContent);
    if(e.target.getAttribute('value')!=='custom'){
      window.location.reload()
    }
    // window.location.reload()
  }
  const handleSelectCustomDate = ()=>{
    if(startDate > endDate){
    }else{
      localStorage.setItem('period', `${startDate},${endDate}`)
      setSmShow(false);
      window.location.reload()
    }
  }
  // const handleEvent = (event, picker)=>{
  //   console.log(picker.startDate._d,'start');
  //   console.log(new Date(picker.endDate._d), 'end');
  // }

  return (
    <nav className="navbar admin-top-green-navbar navbar-light">
      <span  
        className="AD-navbar-brand"
      >
        Dashboard
      </span>
      <div className="d-flex justify-content-end dashboard-drop">
        <Nav.Link>
          <label className="admin-DHB-period position-absolute ms-4">Period</label>
         
          <Dropdown onSelect={handleSelectPeriod}>
           
           <Dropdown.Toggle id="dropdown-basic" className="dept-admin me-2">
             {localStorage.getItem('period_name') ? localStorage.getItem('period_name'): period_selected }
           </Dropdown.Toggle>
           <Dropdown.Menu className="tg-nv-drop-menu dScroll">
             <Dropdown.Item value="all" className="dh-drop">All Time</Dropdown.Item>
             <Dropdown.Item value="month" className="dh-drop">This month</Dropdown.Item>
             <Dropdown.Item value="7" className="dh-drop">Last 7 days</Dropdown.Item>
             <Dropdown.Item value="30" className="dh-drop">Last 30 days</Dropdown.Item>
             <Dropdown.Item value="90" className="dh-drop">Last 90 days</Dropdown.Item>
             <Dropdown.Item value="custom" className="dh-drop" onClick={() => setSmShow(true)}>Custom</Dropdown.Item>
           </Dropdown.Menu>
         </Dropdown>
        </Nav.Link>
        <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
           <h6>Select Date Range</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
        <Form.Control type='date'
         className="create-form mb-1"
         value={startDate}
        onChange={(e)=>setStartDate(e.target.value)}>
         </Form.Control>
        <Form.Control 
        type='date'
        max={new Date().toISOString().split('T')[0]} 
        className="create-form mb-1"
        value={endDate}
        onChange={(e)=>setEndDate(e.target.value)}
        ></Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSelectCustomDate}>Apply</Button>
        </Modal.Footer>
      </Modal>

      </div>
    </nav>
  );
}

export default AdminTopgreen;
