import React, { useEffect, useState } from 'react';
import Navbar from '.././Layout/Navbar/Navbar';
import { Col, Container, Row, Table } from "react-bootstrap";
//import { Oval } from "react-loader-spinner";
//import { useNavigate } from "react-router-dom";
// import { GoPlus } from "react-icons/go";
import "./Schedule.css";
import Tippy from "@tippyjs/react";
import filter from "./../../assets/Icons/Filter.svg";
import execute from "./../../assets/Icons/ExecuteNow.svg";
//import edit from "./../../assets/Icons/Edit.svg";
import publish from "./../../assets/Icons/published.svg";
//import unpublish from "./../../assets/Icons/unpublished.svg";
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EditSchedule from './EditSchedule/EditSchedule';
import CreateSchedule from "./CreateSchedule/CreateSchedule"
import { API } from '../../global';
import { toast } from "react-toastify";


// import Paginate from '../DashboardComponents/Paginate/Paginate';
import DeleteSchedule from './DeleteSchedule/DeleteSchedule';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

function Schedule() {

  const [scheduleList, setSchedule] = useState([]);
  const [scheduleListPagination, setSchedulePagination] = useState([]);
  const [schedule_id, setScheduleId] = useState('');
  const [scheduleStake, setScheduleStake] = useState([]);
  const [applist, setApplist] = useState([]);
  const [check, setCheck] = useState(false);
  const [app_selected, setApp_selected] = useState("");
  const [dropSearchlist, setDropSearchlist] = useState([]);
  const [searchApp, setSearchApp] = useState("");
  const [currentRun, setcurrentRun] = useState("");
  const [currentRunStatus, setcurrentRunStatus] = useState("");
  const project_id = localStorage.getItem("project-id");
  const [searchList, setSearchlist] = useState([]);
  const [sort, setSort] = useState("asc");
  const [expand, setExpand] = useState(false);
  const [page, setPage] = useState(0); 
  const [currentPage, setCurrentPage] = useState(1); 
  // const applicationId = localStorage.getItem('app_id')
  const app_name = localStorage.getItem("app-id");
  
  const theme = createTheme({
    components: {
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#5D3B5E',
              color: 'white',
              '&:hover': {
                backgroundColor: '#805382 !important',
              },
            },
            '&:not(.Mui-selected):hover': {
              backgroundColor: '#805382',
              color:"white"
          },
          },
        },
      },
    }
  });

  useEffect(() => {
    getScheduleList();
    getStakeholder();
    getApplication()
    // eslint-disable-next-line
  }, [project_id])

  const getApplication = () => {

    setApplist([]);
    fetch(
      `${API}/admin/application/${localStorage.getItem("project-id")}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        let newData = [...app.result.data];
        newData.unshift({ name: 'All' })
    
    newData.sort((a, b) => {
      if (a.name === 'All') {
        return -1;
      }
      if (b.name === 'All') {
        return 1;
      }
      return a.name.localeCompare(b.name)
    })
        app.result.data.length > 0 ? setDropSearchlist(newData) : setDropSearchlist([]);
        app.result.data.length > 0 ? setApplist(newData) : setApplist([]);       
          })
  };
  
  //  const navigate = useNavigate();
  const getScheduleList = (a) => {
    fetch(
      `${API}/tests/testsuites/schedule`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        let scheduleArr = [...app.result.data];
       
        if(localStorage.getItem('app_id')){
          const filteredData= scheduleArr.filter(d=>d.testsuite_id.application_id===localStorage.getItem("app_id"));
          setPage(Math.ceil(filteredData.length/10));
          let dataArr = mergeData(filteredData);
          setSchedulePagination(dataArr)
          filteredData.length > 0 ? setSchedule(dataArr.slice(0, 10)) : setSchedule([]);
          filteredData.length > 0 ? setSearchlist(filteredData) : setSearchlist([]);
        }else{
          const filteredData= scheduleArr.filter(d=>d.testsuite_id.project_id===localStorage.getItem("project-id"));
          let dataArr = mergeData(filteredData);
          setPage(Math.ceil(filteredData.length/10));
          setSchedulePagination(dataArr)
          filteredData.length > 0 ? setSearchlist(dataArr.slice(0, 10)) : setSchedule([]);
          filteredData.length > 0 ? setSearchlist(filteredData) : setSearchlist([]);
        }
        if(localStorage.getItem("app-id")==='All'){
          const filteredData= scheduleArr.filter(d=>d.testsuite_id.project_id===localStorage.getItem("project-id"));
          let dataArr = mergeData(filteredData)
          setPage(Math.ceil(filteredData.length/10));
          setSchedulePagination(dataArr)
          filteredData.length > 0 ? setSchedule(dataArr.slice(0, 10)) : setSchedule([]);
          filteredData.length > 0 ? setSearchlist(filteredData) : setSearchlist([]);
        }
        setScheduleId(app.result.data[app.result.data.length - 1].schedule_id);
        a==='reload' && window.location.reload();

      })
  }
  const mergeData =(arr)=>{
    let data = arr.map((a, i)=>({
      id: i+1,
      name:a.testsuite_id.name,
      created:a.created_by.Ad_username,
      updated:a.updated_by.Ad_username,
      r_status:a.testsuite_id.last_run_status,
      ...a
    }))
    return data;
  }
  const handleDropChange =(e)=> {
    setSearchApp(e);
    console.log(e);
    if(e.length === 0 ){
      setApplist(dropSearchlist)
    }else{
      let fillteredApp= dropSearchlist.filter(d=>d.name.toLowerCase().includes(e.toLowerCase()));
      setApplist(fillteredApp)
    }
    
  }
  const getFilter =(value)=> {
    // console.log(value, 'vlaue');
        let addTstoIds = searchList.map((d)=>{
          return( {test_suite_name : d.testsuite_id.name, created:d.created_by.Ad_username,updated:d.updated_by.Ad_username,r_status:d.testsuite_id.last_run_status,...d})
        });   
        const lowercasedValue = value.toLowerCase().trim();
      
        if (lowercasedValue.length === 0){
          setSchedule(searchList)
        } else {
          const filteredData = addTstoIds.filter(item => {
            return Object.keys(item).some(key =>
               item[key].toString().toLowerCase().includes(lowercasedValue)
            );
          });
          // let removeTstoIds = filteredData.map((d)=>{
          //   return {...d, testsuite_id : d.testsuite_id.replace('TS', '')-100}
          // });
          // console.log(removeTstoIds, 'remove');
    
          setSchedule(filteredData);
        }
      } 
      const run_status = async (id,list_id) => {
        setcurrentRun(list_id)
        await fetch(`${API}/tests/testsuites/run/${id}`, {
          method: "POST",
          body: JSON.stringify(),
          headers: {
            "content-type": "application/json",
            authorization: `${localStorage.getItem("access-key")}`,
          },
        })
          .then(() => toast.success("Execution Started Successfully"))
          .then(() => Interval(id));
    
        // props.status(data.data, new_date);
      };
      
  let t;
  const Interval = (id) => {

    t = setInterval(() => {
      // const test_id = localStorage.getItem("testsuite-id")
      // const app_id = localStorage.getItem("app_id")
      // let load = false;
      clearReload(id)
      getScheduleList()
      // props.getTestsuites(app_id,load);
    }, 3000);
  };
  const clearReload = (id) => {
    fetch(`${API}/tests/testsuites/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }) //promise
      .then((value) => value.json()) //Response Object
      .then((tcs) => {
        setcurrentRunStatus(tcs.result.data.last_run_status)
        if(tcs.result.data.last_run_status === "passed" || tcs.result.data.last_run_status === "failed" || tcs.result.data.last_run_status === "skipped" ){
   clearInterval(t);
        }
      })//skipped
  };
  const sorting = (order) => {
    if (sort === "asc") {
      const sorted = [...scheduleList].sort((a, b) => 
        a[order] > b[order] ? 1 : -1
      );
      setSchedule(sorted);
      setSort("dsc");
    }
    if (sort === "dsc") {
      const sorted = [...scheduleList].sort((a, b) => 
        a[order] < b[order] ? 1 : -1
      );
      setSchedule(sorted);
      setSort("asc");
    }
  }
  const handleChange = (event, value) => { 
    // getProject(value) 
    if(value === undefined ) value=currentPage
    let startCount=0;
    let endCount=0;
    let firstValue = value
    if(value===1){
      firstValue=undefined
    }else{
      startCount=(value-1)*10;
      endCount = startCount+10;
    }
    let paginationArr = [...scheduleListPagination];
    if(firstValue){
      setSchedule(paginationArr.slice(startCount, endCount))
     }else{
      setSchedule(paginationArr.slice(0, 10) )
     }    
     setCurrentPage(value)
    // setPage(value); 
};
  const bulkRun =()=>{
    const filterSelectedValue = scheduleList.filter(d=>d.checked===true);
    let filterSelectedValueId = filterSelectedValue.map(a=> a.testsuite_id._id)

    fetch(`${API}/tests/testsuite/bulk_run`, {
      method: "POST",
      body: JSON.stringify(filterSelectedValueId),
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then((res) => res.json())
    .then((b)=>{
      if(b.success){
        toast.success('Run Start Successfully')
        window.location.reload();
      }else{
        toast.error(b.info.msg)
      }
    })
  }
  const checkAllBox = (e) => {
    let allCheck = document.getElementById("allcheck").checked;
    let temp = [...scheduleList];

    temp.forEach(d => {
        d.checked = allCheck;
    });

    setSchedule(temp);
};

  const singleCheck = (a) => {
    // setCheckAll(false);
    let temp = [...scheduleList];
    temp = temp.map((d) => {
      if (d._id === a) {
        d.checked = !check;
      }
      return d;
    });
    if (temp.filter((d) => d.checked === true).length === temp.length) {
      // setCheckAll(true);
    }
    setCheck(!check);
    setSchedule(temp);
  };
  const getStakeholder = () => {
    // setLoading(true)
    fetch(`${API}/admin/stakeholder`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => {
        // setScheduleStake(pro.data)

        setScheduleStake(pro.result.data);
        // setSelectedValue(stakes);
        console.log(pro.result.data, "stakes schedule");
      })
    // .then(() => setLoading(false))
  };

  return (
    <>
      <div className="">
        <Navbar testcaselist={getFilter}/>

        {/*Topgreen */}

        <Container fluid className="position-fixed SH-head">
          <Row className="SH-row">
            <Col sm={5}>
              <div className="d-flex SH-first-head justify-content-start">
                Schedule List
              </div>
            </Col>

            <Col sm={7}>
              <div className="d-flex justify-content-end SH-test-btns">
                <div className="btn-group">
                  <label className="SH-bulk position-absolute">Action</label>
                  <button
                    type="button"
                    className="SH-bulk-btn me-3 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    size="sm"
                    aria-expanded="false"
                  >
                    Bulk Action
                  </button>
                  <ul className="dropdown-menu">
                             <li className="dropdown-item" style={{ fontSize: "14px" }} onClick={bulkRun}
                             onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                  e.preventDefault();
                                  bulkRun();
                              }
                          }}
                             >
                      Execute Now
                    </li>
                  </ul>
                </div>

                <div className="btn-group TC-app">
              <label className="TC-App position-absolute">Application</label>
              <div className="TS-bulk-btn me-3">
              <button
                type="button"
                className="TS-app-btn"
                data-bs-toggle="dropdown"
                size="sm"
                aria-expanded="false"
              >
              {(app_selected && app_selected.length > 10) ? `${app_selected.slice(0, 10)}...` :(app_selected && app_selected.length <= 10) ? app_selected: (app_name && app_name.length > 10)  ? `${app_name.slice(0, 10)}...`: (app_name && app_name.length <= 10) ? app_name : "- - Select - -"}
                </button>
                                <span className="dropdown-toggle"></span>
              <ul className="dropdown-menu text-center">
              {dropSearchlist.length !== 0 && <li><input type="text" value={searchApp} onChange={(e)=>handleDropChange(e.target.value)}/></li>}

                  {applist.length === 0 ? (
                    <li className="dropdown-item dScroll">No Application found</li>
                     ): (  
                  applist.map((app, i) => {
                  return (
                    <li
                      key={i}
                      className="dropdown-item"
                      onClick={() => {
                        getScheduleList('reload');
                        setApp_selected(app.name);
                        localStorage.setItem("app-id", app.name);
                        localStorage.setItem("app_id", app._id)
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            getScheduleList('reload');
                            setApp_selected(app.name);
                            localStorage.setItem("app-id", app.name);
                            localStorage.setItem("app_id", app._id);
                        }
                    }}
                    >
                      {app.name}
                    </li>
                  );
                  })
  )}

              </ul>
              </div>
              </div>

                <div>
                  <CreateSchedule getSchedule={getScheduleList}  count={schedule_id} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Table */}

        <div className='SH-table-main'>

          <Table className="SH-table">
            <thead
              className="align-middle TC-thead"
              style={{
                backgroundColor: "#E6C6E840",
                color: "#805382",
              }}
            >
              <tr className="align-middle">
                <th style={{ borderRight: "2px solid #805382", width: "10%" }}>
                  <input type="checkbox" id="allcheck" onChange={(e)=>checkAllBox(e)} style={{ margin: "6px", zoom: "1" }} />
                  <span onClick={() => setExpand(!expand)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          setExpand(!expand);
                      }
                  }}
                    >
                    {!expand ? (
                      <MdArrowDropDown size={15} />
                    ) : (
                      <MdArrowDropUp size={15} />
                    )}
                  </span>
                  S.No
                  <span onClick={() =>{
                        sorting('id'); setExpand(!expand)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('id');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span>
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                  Test Suite Name
                  <span onClick={() =>{
                        sorting('name'); setExpand(!expand)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('name');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span>
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                  Environment
                  <span onClick={() =>{
                        sorting('environment'); setExpand(!expand)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('environment');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span>
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "13%" }}>
                  Schedule Frequency
                  <span onClick={() =>{
                        sorting('frequency'); setExpand(!expand)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('frequency');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span>
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "10%" }}>
                  Status
                  <span onClick={() =>{
                        sorting('r_status'); setExpand(!expand)
                        }}
                        onKeyDown={(e) => { 
                          if (e.key === 'Enter' || e.key === ' ') {
                            sorting('r_status');
                            setExpand(!expand);
                          }
                        }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span>
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "12%" }}>
                  Created By
                  <span onClick={() =>{
                        sorting('created'); setExpand(!expand)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('created');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span>
                </th>
                <th style={{ borderRight: "2px solid #805382", width: "12%" }}>
                  Updated By
                  <span onClick={() =>{
                        sorting('updated'); setExpand(!expand)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('updated');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span>
                </th>
                <th style={{ width: "15%" }}>
                  <span style={{ marginLeft: "30px", justifyContent: "center", alignContent: "center" }} className="SH-filter-icon">Actions</span>
                  <img
                    src={filter}
                    className="filter"
                    alt="filter icon"
                  />
                </th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              {scheduleList.map((list, i) => (
                <tr style={{ height: "10px" }} className="mt-5">
                  <td>
                    <input type="checkbox" onChange={()=>singleCheck(list._id)} checked={list.checked} style={{ margin: "5px", zoom: "1" }} />{" "}
                    {list.id}
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"

                    >
                      <img
                        src={publish}
                        style={{ marginRight: "10px" }}
                        alt="publish icon"
                      />
                    </Tippy>

                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"
                      content={list.testsuite_id.name}
                    >
                      <span data-tip="hello world">
                        {list.testsuite_id.name}
                      </span>
                    </Tippy>
                  </td>
                  <td>
                    <Tippy
                      placement="top"
                      className="tippy-tooltip"
                      theme="light"
                      content={list.environment}
                    >
                      <span data-tip="hello world">
                        {list.environment}
                      </span>
                    </Tippy>
                  </td>
                  <td>

                    <span data-tip="hello world">{list.frequency}</span>

                  </td>
                  <td>
                    {currentRun === list._id ? currentRunStatus : list.testsuite_id.last_run_status }
                  </td>

                  <td>
                    {list.created_by.Ad_username}
                  </td>
                  <td>
                    {list.updated_by.Ad_username}
                  </td>
                  <td>
                    <div className="SH-icons">
                      <Tippy
                        placement="top"
                        className="tippy-tooltip"
                        theme="light"
                        content="Execute"
                        
                      >
                        <img
                          src={execute}
                          className="action"
                          style={{ cursor: "pointer" }}
                          alt="execute icon"
                          onClick={()=>run_status(list.testsuite_id._id, list._id)}
                          onKeyDown={(e) => {    
                            if (e.key === 'Enter' || e.key === ' ') {
                              run_status(list.testsuite_id._id, list._id);
                            }
                          }}
                        />
                      </Tippy>

                      <EditSchedule editData={list} getSchedule={getApplication} stakeData={scheduleStake} />
                      <DeleteSchedule deleteId={list._id} getSchedule={getApplication} />
                    </div>
                  </td>
                </tr>
              ))
              }

            </tbody>
          </Table>

        </div>
        <div className='page-head fixed-bottom'>

<ThemeProvider theme={theme}>
  <Pagination count={page} page={currentPage} onChange={handleChange} variant="outlined" shape="rounded" /> 
  </ThemeProvider>
</div>
      </div>
    </>
  )
}

export default Schedule