import React, { useEffect, useState } from 'react';
import Navbar from "../../components/Layout/Navbar/Navbar"; 
import "./Freemium.css";
import { API } from '../../global';



const FreemiumLicense = () => {  
  
  const [planList, setPlanList ] = useState([]);
  const [tenat, setTenant ] = useState({});

  useEffect(()=>{
    getPlanList();
    getTenant();
  },[])

  const getPlanList =()=> {
    fetch(
      `${API}/superadmin/plan/list`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((list)=> {
        setPlanList(list.result)
        console.log(list.result,'plan');
      })
  }

  const getTenant =()=> {
    fetch(
      `${API}/superadmin/tenant/get`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((list)=> {
        console.log(list.result,'tenant');
        setTenant(list.result)
      })
  }
  return (
    <div>
    <Navbar />
    <div className="sub-license">
    <div className="license-green pl-4 py-3">
    <span className="pl-2 fw-bold">License</span>
    </div>

    <div className="license-detail d-flex gap-5 mt-4 items-align-center">
    <div className="lic-key">
    <p className="mb-4 mt-1">Current Plan</p>
    <p>Company</p>
    <p>No. of Projects</p>
    <p>No. of Test Cases</p>
    <p>No. of Test Suites</p>
    <p>No. of Test Execution</p>
    </div>
    {
      planList.map((plan)=>{
        return(
          <>
         { tenat.current_plan === plan._id && <div className="lic-value">
          <button className="bg-secondary px-3 py-1 rounded-1 text-white fw-bold btn-sm">{plan.name}</button>
          <p className="fw-bold">{tenat.name}</p>
          <p className="fw-bold">{plan.total_projects}</p>
          <p className="fw-bold">{plan.total_testcases}</p>
          <p className="fw-bold">{plan.total_testsuites}</p>
          <p className="fw-bold">{plan.total_execution
      }/month</p>
          </div>}
          </>
        )
      })
    }
  
    </div>
        <button className="upgrade-btn btn-sm px-3 rounded-1">Upgrade Plan</button> 

        <div className="license-cards mt-4">
        <div className="container-fluid text-center">
  <div className="row px-5 flex-nowrap gap-3 w-100">

    <div className="col-3 col-sm-3 card-1 border border-dark-subtle p-2 rounded-4 shadow-sm">
    <h5 className="mb-1 py-2 fw-bold">Our Plans</h5>
    <p className="mt-2">No. of Projects</p>
    <p className="py-1 bg rounded-1">No. of Test cases</p>
    <p className="py-">No. of Test suites</p>
    <p className="py-1 bg rounded-1">No. of Test execution</p>
    </div>
    {
      planList.map((plan)=>{
        return(
          <>
    {tenat.current_plan !==plan._id && <div className="col-3 col-sm-3 card-2 border border-info rounded-4 p-2">
    <h5 className="mb-1 py-2 fw-bold bg rounded-3 text-white">{plan.name}</h5>
    <p className="mt-2">{plan.total_projects}</p>
    <p className="py-1 bg rounded-1">{plan.total_testcases}</p>
    <p>{plan.total_testsuites}</p>
    <p className="py-1 bg rounded-1">{plan.total_execution}/month</p>
    </div>
    }
    </>
        )
      })
    }
     
 
  </div>
</div>
        </div>
    </div>
    </div>
  )
}

export default FreemiumLicense