import React, {useState} from 'react';
import './Freemium.css';
// import FreemiumLogin from "../../components/Freemium/FreemiumLogin";
// import FreemiumBanner from "../../components/Freemium/FreemiumBanner";
// import FreemiumBg from "../../components/Freemium/FreemiumBg";
import heading from '../../assets/img/heading.png';
import warning from '../../assets/img/warning.png';
import { toast } from "react-toastify";
import Form from 'react-bootstrap/Form';
import { API } from '../../global';
import { useNavigate } from "react-router-dom";


const FreemiumRegister = () => {
    // const [showReg, setShowReg] = useState(false);
    const [regName, setRegName] = useState('');
    const [regEmail, setRegEmail] = useState(localStorage.getItem('regEmail') ? localStorage.getItem('regEmail') : '');
    const [regCompany, setRegCompany] = useState('');
    // const [error, setError] = useState('');
    const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  const registerUser = {
    name:regCompany, 
    description:"Register via portal",                
    tenant_key:regEmail.split(/[.@]/)[1],
    email:regEmail,
    auth_type:"basic_auth",
    role_name: ["Quality Engineer", "Admin"],
    Ad_username:regName
  }
  const validate = () => {
    const regex = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;

    let errors = {};
    if (!registerUser.name) {
      errors.name = "Cannot be blank";
    } 
    if (!registerUser.email) {
      errors.email = "Cannot be blank";
    } else if (!regex.test(registerUser.email)) {
      errors.email = "Enter valid email";
    }
    if (!registerUser.Ad_username) {
      errors.Ad_username = "Cannot be blank";
    } 
    return errors;
  };
  const handleRegister = (e) => {
    validate()
    if(Object.keys(validate()).length === 0) {

     e.preventDefault();
     fetch(`${API}/users/register`, {
      method: "POST",
      body: JSON.stringify(registerUser),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((a) => a.json())
      .then((b)=>{
       localStorage.removeItem('regEmail')
        if(b.info.msg==="Email already exists"){
          toast.error(b.info.msg);
          navigate('/login')
        }else{
          toast.success(b.info.msg);
          navigate('/verify')
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }else{
      setFormErrors(validate());
    }
 
  };


           
  return (
    <>

<div className="container-fluid">
      <div className="row">
        <div className="col-5 leftbanner d-flex align-items-center justify-content-center">
          <div className="banner-main d-flex flex-column justify-content-center align-items-center">
            <img src={heading} alt="title" className="img-fluid w-50" />
            <p className="text-center w-75 mt-5 text-white">
              It is the ultimate solution for organizations looking to automate their Testcases,
               test suites and unlock the full potential of AI-driven testcases creation. With its intuitive interface,
                powerful capabilities, and unrivalled performance, Ignitho quality accelerator empowers businesses 
                to deliver exceptional service.</p>
          </div>
        </div>

        <div className="col-7 rightBanner d-flex align-items-center justify-content-center">         
     <Form className="reg-main d-flex flex-column justify-content-center mx-auto bg-white p-5 rounded"> 
     <h5 className="forms-reg text-center fw-bold">Register</h5>
<Form.Group className="mb-3 name-input">
                <Form.Label className="fw-bold">Full Name</Form.Label>
                 {/* {nameError && <div className="reg-error">{nameError}</div>} */}
                <Form.Control size="sm" type="text" placeholder="Enter your full name" onChange={(e) => {
                  setRegName(e.target.value)
                  if(e.target.value.length !== 0) {
                    setFormErrors(current => {
                  const {Ad_username, ...rest} = current;
                  return rest
                     });
                   }else if(e.target.value.length === 0){
                    setFormErrors(current => {
                      const changes =  {...current, Ad_username:"Cannot be blank"}
                      return changes
                     })
                   } 
                  }} value={regName}/>
              </Form.Group>
              {formErrors.Ad_username && (
            <span className="user-error d-flex align-items-center justify-content-start gap-2"><img src={warning} alt="error" />{formErrors.Ad_username}</span>
          )}

  <Form.Group className="mb-3 email-input">
                <Form.Label className="fw-bold">E-Mail ID</Form.Label>
                 {/* {emailError && <div className="reg-error">{emailError}</div>} */}
                <Form.Control size="sm" type="email" placeholder="Enter your email" onChange={(e) => {
                  setRegEmail(e.target.value)
                  if(e.target.value.length !== 0) {
                    setFormErrors(current => {
                  const {email, ...rest} = current;
                  return rest
                     });
                   }else if(e.target.value.length === 0){
                    setFormErrors(current => {
                      const changes =  {...current, email:"Cannot be blank"}
                      return changes
                     })
                   } 
                  }} value={regEmail}/>
              </Form.Group> 
              {formErrors.email && (
            <span className="user-error d-flex align-items-center justify-content-start gap-2"><img src={warning} alt="error" />{formErrors.email}</span>
          )}

              <Form.Group className="mb-3 pass-input">
                <Form.Label className="fw-bold">Company Name</Form.Label>
                 {/* {companyError && <div className="reg-error">{companyError}</div>} */}
                <Form.Control size="sm" type="text" placeholder="Enter your company name" onChange={(e) => {
                  setRegCompany(e.target.value)
                  if(e.target.value.length !== 0) {
                    setFormErrors(current => {
                  const {name, ...rest} = current;
                  return rest
                     });
                   }else if(e.target.value.length === 0){
                    setFormErrors(current => {
                      const changes =  {...current, name:"Cannot be blank"}
                      return changes
                     })
                   } 
                  }} value={regCompany}/>
              </Form.Group>
              {formErrors.name && (
            <span className="user-error d-flex align-items-center justify-content-start gap-2"><img src={warning} alt="error" />{formErrors.name}</span>
          )}
         <button type="button" className="btn btn-register" onClick={handleRegister}>Submit</button>
</Form> 
          </div>
        </div>
        </div>

    </>
  )
}

export default FreemiumRegister
