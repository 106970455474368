

import "./TestcaseReport.css";
import Navbar from "../../Layout/Navbar/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../../global";
import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import moment from "moment";
import { useRef } from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import publish from "../../../assets/Icons/published.svg";

function TsReport() {
  const [isDrawOpen, setIsDrawOpen] = useState(false);
  const [all_cases, setAll_cases] = useState([]);
  const [run_log_status, setRunLog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [app_selected, setApp_selected] = useState("");
  const [testcase_selected, setTestcase_selected] = useState("");
  const [testcaselist, setTestcaselist] = useState([]);
  const [dropSearchlist, setDropSearchlist] = useState([]);
  const [searchApp, setSearchApp] = useState("");
  const [dropSearchlistCase, setDropSearchlistCase] = useState([]);
  const [searchCase, setSearchCase] = useState("");


  const ref = useRef();

  const { proId } = useParams();

  const navigate = useNavigate();

  const project_id = localStorage.getItem("project-id")
  const applicationId = localStorage.getItem('app_id')
  const app_name = localStorage.getItem("app-id");
  const [applist, setApplist] = useState([]);
  const [tick, setTick] = useState("");
  const [tickMark, setTickMark] = useState(false);
  const [dataId, setDataId] = useState("");
    const [sort, setSort] = useState("asc");

  const get_project_id = () => {
    if (localStorage.getItem("project-id")) {
      navigate(`/qe/testcase/reports/${project_id}`);
    }
  };


  useEffect(() => {
    get_project_id();
    if (applicationId) {
      testcasesByAppId(applicationId)
      getApplication()
    }
    else {
      getTestcasesByprojectId();
      getApplication();
    }
    // eslint-disable-next-line
  }, [proId])

  const getApplication = () => {
    setApplist([]);
    fetch(
      `${API}/admin/application/${localStorage.getItem("project-id")}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        console.log(app);
         app.result.data.length > 0 ? setApplist(app.result.data.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : setApplist([]);
         setDropSearchlist(app.result.data) ;
      })

  };


  // testcases by project id
  const getTestcasesByprojectId = async () => {
    // setLoading(true);
    setTestcaselist([]);
    setApp_selected('')
    setAll_cases([])
    await fetch(
      `${API}/tests/testcases/application/?project_id=${project_id}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((test) => {
        setTestcaselist(test.result.data.sort((a, b) => a.test_case_name.toLowerCase() > b.test_case_name.toLowerCase() ? 1 : -1));
      })

    // .then(() => setLoading(false));
  };
  // testcase by application id
  const testcasesByAppId = (id) => {
    // setLoading(true);
    setTestcaselist([]);
    fetch(
      `${API}/tests/testcases/application/?application_id=${id}&&project_id=${project_id}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((test) =>{ 
        setTestcaselist(test.result.data.sort((a, b) => a.test_case_name.toLowerCase() > b.test_case_name.toLowerCase() ? 1 : -1));
        setDropSearchlistCase(test.result.data)
      })
      .then(() => {
        setAll_cases([])
        setTestcase_selected("")
      })
    // .then(() => setLoading(false));
  };

  // run logs by testcase id
  const testcase_duration = (id) => {
    setLoading(true)
    fetch(`${API}/tests/testcases/runlogs/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }) //promise
      .then((data) => data.json()) //Response Object
      .then((ts) => {
        // setRunDuration(ts.data.reduce((a, b) => +a + +b.run_duration, 0));
        let dataArr = [...ts.result.data.data]
        dataArr = dataArr.map((a)=>({
          name:a.executed_by.Ad_username,
          ...a
        }))
        setAll_cases(dataArr);
        setLoading(false)
      });
  };
  const handleDropChangeApp =(e)=> {
    setSearchApp(e);
    console.log(e);
    if(e.length === 0 ){
      setApplist(dropSearchlist)
    }else{
      let fillteredApp= dropSearchlist.filter(d=>d.name.toLowerCase().includes(e.toLowerCase()));
      setApplist(fillteredApp)
    } 
  }
  const handleDropChangeCase =(e)=> {
    setSearchCase(e);
    console.log(e);
    if(e.length === 0 ){
      setTestcaselist(dropSearchlistCase)
    }else{
      let fillteredApp= dropSearchlistCase.filter(d=>d.test_case_name.toLowerCase().includes(e.toLowerCase()));
      setTestcaselist(fillteredApp)
    } 
  }

  //sort function

    const sorting = (order) => {
    if (sort === "asc") {
      const sorted = [...all_cases].sort((a, b) => 
        a[order] > b[order] ? 1 : -1
      );
      setAll_cases(sorted);
      setSort("dsc");
    }
    if (sort === "dsc") {
      const sorted = [...all_cases].sort((a, b) => 
        a[order] < b[order] ? 1 : -1
      );
      setAll_cases(sorted);
      setSort("asc");
    }
  }

  const onClick = (val, i) => {
    // setIsDrawOpen(true)

    setTickMark(true);
    // console.log("data")
    // let uniqueId = val._id;
    // console.log(all_cases.indexOf(val) === i ,"data")
    setTick(val._id)
    setDataId(val._id)
    setRunLog([]);
    if (dataId === '') {

      setIsDrawOpen(true)

    } else if (dataId === val._id) {

      setIsDrawOpen(current => !current)


    } else {

      setIsDrawOpen(true)


    }
    // setIsDrawOpen(current=> !current)
    // setIsDrawOpen(current => dataId === val._id ? !current : current)
    // setIsDrawOpen(dataId === val._id ? false : true)
    // setIsDrawOpen(all_cases.indexOf(val) !== i && false)
    // setIsDrawOpen((current) => all_cases.indexOf(val) === i && !current);
    // setIsDrawOpen()
    let log_parse = JSON.parse(val.run_log);
    if (log_parse.length > 0) {
      setRunLog(log_parse[0].elements[0].steps);
      // setAllLogData(log_parse[0].elements[0]);
      console.log(log_parse[0].elements[0]);
    }

  };

  const [expand, setExpand] = useState(false);

  // const testcaseId = localStorage.getItem("ts-id")
  return (
    <>
      <Navbar />
      {/* <Topgreen /> */}
{ loading ? <div style={{ marginTop: "20%", marginLeft: "5%" }}>
          <Oval
            height={80}
            width={2000}
            color="#805382"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#F0E3F1#AB78AE"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>

:<>
      {/*Here creating TopGreen Head */}
      <Container fluid className="testcase-report-nav">
        <Row className="report-test-row">
          <Col md={5}>
            <div className="d-flex first-head justify-content-start">
              Test Case Execution Reports
            </div>
          </Col>
          <Col md={7}>

            <div className="d-flex justify-content-end test-btns">


              <div className="btn-group">
                <label className="TC-App position-absolute">Application</label>
                <button
                  type="button"
                  className="TC-bulk-btn-TCR me-3 dropdown-toggle"
                  data-bs-toggle="dropdown"
                  size="sm"
                  aria-expanded="false"
                >
              {(app_selected && app_selected.length > 10) ? `${app_selected.slice(0, 10)}...` :(app_selected && app_selected.length <= 10) ? app_selected: (app_name && app_name.length > 10)  ? `${app_name.slice(0, 10)}...`: (app_name && app_name.length <= 10) ? app_name : "- - Select - -"}
                </button>
                <ul className="dropdown-menu dScroll">
                {dropSearchlist.length !== 0 && <li><input type="text" value={searchApp} onChange={(e)=>handleDropChangeApp(e.target.value)}/></li>}

                   {applist.length === 0 ? (
                    <li className="dropdown-item dScroll">No Application found</li>
                     ):(
                  applist.map((app, i) => {
                      return (
                        <li
                        key={i}
                        tabIndex={0}
                        className="dropdown-item"
                        onClick={() => {
                          setIsDrawOpen(false);
                          testcasesByAppId(app._id);
                          setApp_selected(app.name);
                          localStorage.setItem("app-id", app.name);
                          localStorage.setItem("app_id", app._id);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            // Trigger the click handler when Enter or Space is pressed
                            e.preventDefault(); // Prevent default behavior to avoid unexpected scrolling
                            e.target.click(); // Programmatically trigger the click event
                          }
                        }}
                      >
                        {app.name}
                      </li>
                      
                      )
                  })
  )}
                </ul>
              </div>

              <div className="btn-group">
                <label className="TC-suite position-absolute">Test Case</label>
                <button
                  type="button"
                  className="TC-bulk-btn-TCR me-3 dropdown-toggle"
                  data-bs-toggle="dropdown"
                  size="sm"
                  aria-expanded="false"
                >
                  {(testcase_selected && testcase_selected.length > 10) ? (`${testcase_selected.slice(0, 10)}...`) : (testcase_selected&&testcase_selected.length <= 10)? testcase_selected : "- - Select - -"}
                </button>
                <ul className="dropdown-menu dScroll">
                  {/* <li
      className="dropdown-item"
      onClick={() => {
        getTestcasesByprojectId();
        setTestsuite_selected("All");
      }}
    >
      All
    </li> */}
                    {dropSearchlistCase.length !== 0 && <li><input type="text" value={searchCase} onChange={(e)=>handleDropChangeCase(e.target.value)}/></li>}

                  {testcaselist.length === 0 ? "No Testcase Found"
                    : testcaselist.map((test, i) => {
                      return (
                        <li
  key={i}
  tabIndex={0}
  className="dropdown-item"
  onClick={() => {
    testcase_duration(test._id);
    setTestcase_selected(test.test_case_name);
    // Uncomment if needed
    // localStorage.setItem("tcSelected", test.test_case_name);
    // localStorage.setItem("tcsID", test._id);
  }}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault(); // Prevent default behavior to avoid unexpected scrolling
      e.target.click(); // Programmatically trigger the click event
    }
  }}
>
  {test.test_case_name}
</li>

                      )
                    })}

                </ul>
              </div>
              <div className="d-flex justify-content-end report-icons me-1 mt-3">

                {/* 
                <Pdf targetRef={ref} filename="document.pdf">
                  {({ toPdf }) => (
                    <button
                      style={{ border: "none" }}
                      onClick={toPdf}
                      className="button"
                    >
                      <img
                        src={DownloadReport}
                        className="reportIcon-download"
                        style={{ cursor: "pointer" }}
                        alt="dReport icon"
                        onClick={() => {
                    
                        }}
                      />
                    </button>
                  )}
                </Pdf> */}

                {/* <img
                  src={Back}
                  className="reportIcon-download"
                  style={{ cursor: "pointer" }}
                  alt="back icon"
                  onClick={() => navigate(-1)}
                /> */}
              </div>


            </div>
          </Col>
        </Row>
      </Container>

      {/*Here starting the Execution summary part*/}


      <div className="TC-repo-Execution-main-head row overflow-hidden">


        <div ref={ref}
          className={`TC-repo-Execution-sum-head align-middle ${isDrawOpen && " col-8"
            }`}
          id="report-export">

          <div className="testing">
            <div className="tcr_Execution_test_report_head row">

              <div className="TC-repo-table-header">
               { testcase_selected && <table className="TC-repo-testcase-report-table">
                  <thead className="repo-thead">
                    <tr>
                      <th scope="col">Execution Time
                      <span onClick={() =>{
                        sorting('createdAt'); setExpand(!expand)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('createdAt');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span></th>
                      <th scope="col">Environment<span onClick={() => {sorting('dev'); setExpand(!expand) }}
                         onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('dev');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span></th>
                      <th scope="col">Executed By<span onClick={() => {sorting('name'); setExpand(!expand) }}
                         onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('name');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span></th>
                      <th scope="col">Test Duration<span onClick={() => {sorting('run_duration'); setExpand(!expand)}}
                         onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('run_duration');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span></th>
                      <th scope="col">Status<span onClick={() => {sorting('run_status'); setExpand(!expand)}}
                         onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('run_status');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span></th>
                    </tr>
                  </thead>
                  <tbody className="align-middle">
                    {all_cases.map((data, i) => (
                      <tr key={i} onClick={() => onClick(data)} style={{ cursor: 'pointer' }} >
                        <td>
                          <span className="ms-2" style={{ float: "left", verticalAlign: "center" }}>{data._id === tick && tickMark ? (
                          <img src={publish} alt="" />
                        ) : (
                          ""
                        )}
                          {/* {i === 0 && tickMark ? (
                                    <img className="" src={publish} alt="" />
                                  ) : (
                                    ""
                                  )} */}</span>{moment(data.createdAt).format("D MMM h:mm a")}</td>
                        <td style={{ cursor: 'pointer' }}>Dev Environment</td>
                        <td>{data.executed_by.Ad_username}</td>
                        <td>
                          {moment(data.run_duration, "ss").format("HH:mm:ss")}
                        </td>
                        <td
                          style={{
                            color: data.run_status === "passed" ? "green" : "red",
                          }}
                        >
                          {data.run_status}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>}
              </div>
            </div>
          </div>
        </div>


        <div
          className={`TC-repo-sidebar-main-header ${isDrawOpen ? "col-3" : "report_sidebar-close"
            }`}
        >
          <div className="mb-3">Logs</div>
          <div className="report_sidebar">
            <input type="hidden" value={dataId} id="uniqueId" />

            <table className="report_sideTable">
              <tbody>
                {run_log_status.length > 0 ? (
                  run_log_status.map((data, i) => (
                    <tr className="report_sideTr">
                      <td className="report_sideTd td-1 ml-4 align-middle text-center">{1 + i}</td>

                      <td className="report_sideTd ml-3">
                        <div>{data.name}</div>
                        <div>
                          {moment(data.result.duration, "ss").format(
                            "HH:mm:ss"
                          )}
                        </div>
                        <div
                          style={{
                            color:
                              data.result.status === "passed"
                                ? "green"
                                : "red",
                          }}
                        >
                          {data.result.status}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    Unable to execute given test case, Please check the
                    gherkin language entered in the Create testcase editor
                  </span>
                )}
              </tbody>
            </table>


          </div>

        </div>
      </div>
      </>}

    </>
  );
}

export default TsReport;
