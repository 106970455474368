import React from "react";
// import { useEffect } from "react";
import Overview from "../../DashboardComponents/Overview/QE/Overview";
import Statistics from "../../DashboardComponents/Statistics/Statistics";
import Summary from "../../DashboardComponents/Summary/Summary";
import Topgreen from "../../DashboardComponents/Topgreen/Topgreen";
import Header from "../../Layout/Navbar/Navbar";
import "./Dashboard.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
// import { useIsAuthenticated } from "@azure/msal-react";

function DashboardQE() {
  // const isAuthenticated = useIsAuthenticated();
  const project_id = localStorage.getItem('project-id')

  return (
    <div className="dashboard overflow-hidden">
      <div className="board-dash">
        <Header />
      </div>
      <div className="overview">
        {project_id ? <div className="dash-head">
          <Topgreen />
          <Overview />
          <Summary />
          <Statistics />
        </div> : <div style={{ marginTop: "19%",marginLeft: "45%" }}>
       <div style={{marginLeft: "10%" }}> <AiOutlineInfoCircle size="30px" color="#9F9F9F" /></div>
        <h6 style={{ color: "#9F9F9F" }}>Please Select a Project</h6>
      </div> }
        
      </div>
    </div>
  );
}

export default DashboardQE;
