// import Button from "react-bootstrap/Button";
import "./SignIn.css";
import logo1 from "./../../assets/Icons/Ignitho QA Logo - 2.svg";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useNavigate } from "react-router-dom";
// import { getByLabelText } from "@testing-library/react";
// import { useState } from "react";
import { API } from "../../global";
import { useState } from "react";

export default function SignIn() {
  const { instance } = useMsal();

  const navigate = useNavigate();

  const [role, setRole] = useState([]);
  const [role_name, setRole_name] = useState("");

  // login function with azure AD
  const handleLogin = async (instance) => {
    await instance
      .loginPopup(loginRequest)
      .then((res) => {
        localStorage.setItem("azure-token", res.accessToken);
        getRoles(res.idTokenClaims.groups);
        console.log(res);
        createUser(res);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const createUser = (res) => {
    let tenant_key =res.account.username.split(/[.@]/)
    // console.log("response", res);
    fetch(`${API}/users`, {
      method: "POST",
      body: JSON.stringify({
        tenant_id: res.tenantId,
        Ad_username: res.account.name,
        email:res.account.username,
        tenant_key:tenant_key[tenant_key.length - 2],
        Ad_id: res.uniqueId,
        auth_type:'singleSignOn',
        role_name: ["Quality Engineer", "Admin"],
        Ad_group_id: res.idTokenClaims.groups,
        // Ad_group_name: "",
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((value) => value.json())
      .then((result) => {
        setRole(result.result.data.role_name);
        navigate_user(result.result.data.role_name);
        localStorage.setItem("user-id", result.result.data._id);
        localStorage.setItem("access-key", result.result.token);
        console.log("exp", result.result.token.expiresIn);

        // completion

        localStorage.setItem("role", JSON.stringify(result.result.data.role_name));
      });
  };

  const getRoles = (temp) => {
    fetch(`${API}/roles`, {
      method: "GET",
   
    })
      .then((value) => value.json())
      .then((res) => {
        for (let i = 0; i < res.result.data.length; i++) {
          if (temp.includes(res.result.data[i].ad_group_id)) {
            setRole(...res.result.data[i].role_name);
          }
        }
      });
  };

  const role_navigation = () => {
    if (role_name === "Quality Engineer") {
      // localStorage.setItem("role-name", role_name);
      navigate("/qe/dashboard");
      window.location.reload();
    } else if (role_name === "Admin") {
      // localStorage.setItem("role-name", role_name);
      navigate("/admin/dashboard");
      window.location.reload();
    }
  };

  const navigate_user = (user) => {
    if (user.length === 1) {
      navigate(`/${user[0]}/dashboard`);
      window.location.reload();
    }
  };

  // const token = localStorage.getItem("azure-token");
  // const current_role = localStorage.getItem("role-name");

  return (
    <div className="signin-page">
      <div className="login-box">
        <div className="logo">
          <img src={logo1} className="h-100 w-100" alt="Ignitho Logo" />
        </div>
        <br />
        <div>Sign in with single sign-on</div>
        <div className="btn-text">
          <div className="sign-text">
            <div>
              {role.length > 1 ? (
                <select
                  className="form-control"
                  onChange={(event) =>{
                    setRole_name(event.target.value)
                    localStorage.setItem("role-name", event.target.value
                    );
                  }}
                >
                  <option
                    className="form-control"
                    disabled="disabled"
                    selected={true}
                  >
                    Select a Role
                  </option>

                  {role.map((val, i) => {
                    return (
                      <option className="form-control" key={i} value={val}>
                        {/* TODO: remove this hack and add a display name entry in role table > */}
                         {val === 'Quality Engineer'? "Quality Engineer" : null }
                         {val === 'Admin'? "Admin": null }
                      </option>
                    );
                  })}
                </select>
              ) : (
                ""
              )}
            </div>
            {role.length > 1 ? (
              <button className="btn-login" onClick={() => role_navigation()}>
                Continue
              </button>
            ) : (
              ""
            )}
            {role.length > 1 ? (
              ""
            ) : (
              <button
                className="btn-login"
                onClick={() => {
                  handleLogin(instance);
                }}
              >
                SIGN IN
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

// if group id is found but if it is not there in database - we need to update the db by creating a function
// if group id is not found but if it is there in database - we need to delete that in the db by creating a function
