import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./ScheduleTestsuite.css";
import Form from "react-bootstrap/Form";
import chrome from "./../../../assets/Icons/Chrome.svg";
import safari from "./../../../assets/Icons/Safari.svg";
import firefox from "./../../../assets/Icons/Firefox.svg";
import edge from "./../../../assets/Icons/Edge.svg";
import apple from "./../../../assets/Icons/iOS.svg";
import android from "./../../../assets/Icons/Android.svg";
import schedule from "../../../assets/Icons/Schedule-run.svg";
// import { Table } from "react-bootstrap";
import { useEffect } from "react";
import { API } from "../../../global";
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import { toast } from "react-toastify";

const options = [
    {
        name: "Disable backdrop",
        scroll: false,
        backdrop: false,
        placement: "end",
    },
];

function OffCanvasExample({ ...props }) {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        // setCurentId(false)
    };
    const toggleShow = () => setShow((s) => !s);

    // const [projectlist, setProjectlist] = useState([]);
    // const [projectId, setProjectId] = useState("");
    const [environment, setEnvironment] = useState("Dev Environment");
    const [version, setVersion] = useState("chrome-100");
    // const [schedule, setSchedule] = useState({});
    // const [tenant_id, setTenant_id] = useState('');
    const [frequency, setFrequency] = useState('');
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');
    // const [date, setDate] = useState('');
    const [day, setSelectDay] = useState('')
    const [showDay, setShowDay] = useState(false)
    const [session, SetSelectSession] = useState('')
    const [ShowSession, setShowSession] = useState(false)
    // const [testsuite_id, setTestsuite_id] = useState('');
    const [selectImg, setSelectImg] = useState(false);
    // const [testsuiteList, setTestsuiteList] = useState([]);
    // const [scheduleStake, setScheduleStake] = useState([]);
    // const [testsuite_id, setTestsuite_id] = useState('');
    // const [testsuiteList, setTestsuiteList] = useState([]);
    // const [scheduleList, setScheduleList] = useState([]);
    // const [currentId, setCurentId] = useState(false);
    let suiteProps = { ...props }
    console.log(suiteProps.scheduleId, 'popo');

    useEffect(() => {
        getTestSuite();

    }, [])

    const handleEnvironment = (event) => {
        setEnvironment(event.target.value);
    };
    const handleVersion = (event) => {
        setVersion(event.target.value);
    };

    const handleFrequency = (event) => {
        setFrequency(event.target.value);
        if (event.target.value === 'Weekly') {
          setShowDay(true)
          setShowSession(false);
        } if (event.target.value === 'Monthly') {
          setShowSession(true);
          setShowDay(false)
        }
        if (event.target.value === 'Daily') {
          setShowDay(false);
          setShowSession(false);
        }
    
      };
    
      const handleDay = (event) => {
        setSelectDay(event.target.value);
      };
      const handleSession = (event) => {
        SetSelectSession(event.target.value);
      };

  const handleClick = () => {
    setSelectImg(!selectImg);
  }
    //tests/testsuites/schedule/getid/100
    // const handleSuiteId = (event) => {
    //     setTestsuite_id(event.target.value)
    // }

    const scheduleObj = {
        start_date: date,
        frequency: frequency,
        time: time,
        day: day,
        session: session,
        testsuite_id: suiteProps.scheduleId,
        environment: environment,
        version: version,
        // stakeholder: mailList,
        created_by: localStorage.getItem('user-id'),
        updated_by: localStorage.getItem('user-id')
      }

    const postSchedule = () => {
        console.log(scheduleObj,'obj');
        if(date ==='' || frequency === '' || time === '' || environment === '' || version === ''){
            // if(day ==='' ||session ===''){
              toast.error('Fill the Details');
            //   mailList = [];
            // }
            
          }else{
        fetch(`${API}/tests/testsuites/schedule`, {
            method: "POST",
            body: JSON.stringify(scheduleObj),
            headers: {
                "content-type": "application/json",
                authorization: `${localStorage.getItem("access-key")}`,
            },
        }).then(() => {
            //  suiteProps.getApp();
            // getApplication()
            toast.success('Scheduled Successfully')
            handleClose();
            // setCurentId(true)
        }).catch((err) => {
            console.log(err);
        })
    }
    }
    const getTestSuite = () => {

        fetch(
            `${API}/tests/testsuites/`,
            {
                method: "GET",
                headers: {
                    authorization: `${localStorage.getItem("access-key")}`,
                },
            }
        )
            .then((val) => val.json())
            .then((app) => {
                console.log(app.data);
                // app.data.length > 0 ? setTestsuiteList(app.data) : setTestsuiteList([]);
            })
    }
    // const getApplication = () => {
    //     fetch(
    //         `${API}/tests/testsuites/schedule/getid/${currentId ? suiteProps.scheduleId : suiteProps.scheduleId + 1}`,
    //         {
    //             method: "GET",
    //             // headers: {
    //             //   authorization: `${localStorage.getItem("access-key")}`,
    //             // },
    //         }
    //     )
    //         .then((val) => val.json())
    //         .then((app) => {
    //             app.data.length > 0 ? setScheduleList(app.data) : setScheduleList([]);
    //         })
    // }

    return (
        <>
            <Tippy placement="top" theme="light" content="Schedule"  className="tippy-tooltip">
            <img
  src={schedule}
  onClick={toggleShow}
  style={{ cursor: "pointer" }}
  alt="schedule icon"
  tabIndex={0}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      toggleShow();
    }
  }}
/>

            </Tippy>

            <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        className="CSH-Canvas-create-head"
      >
        <Offcanvas.Body>
          <Offcanvas.Title className="CSH-create mt-4">
            Create Schedule
          </Offcanvas.Title>
          {/* <Form.Group>
            <Form.Label className="create-label mt-4">
              Select Test Suite
            </Form.Label>
            <Form.Select
              type="text"
              value={testsuite_id}
              className="select-cr-CSH-env"
              required
              onChange={handleSuiteId}
            >
              {
                testsuiteList.map(list => (
                  <option value={list._id}>
                    {list.name}
                  </option>
                ))
              }
            </Form.Select>
          </Form.Group> */}

          <Form.Group className="mt-3" value={environment}>
            <Form.Label className="CSH-edit-label">
              Select Environment
            </Form.Label>
            <Form.Select
              as="select"
              className="select-cr-CSH-env"
              value={environment}
              onChange={handleEnvironment}
            >
              <option value="Alpha Testing Environment">
                Alpha Testing Environment
              </option>
              <option value="Dev Environment">Dev Environment</option>
              <option value="Cloud Environment">Cloud Environment</option>
              <option value="Performance Testing Environment">
                Performance Testing Environment
              </option>
              <option value="System Integrating Testing(SIT)">
                System Integrating Testing(SIT)
              </option>
              <option value="User Acceptance Testing(UAT)">
                User Acceptance Testing(UAT)
              </option>
              <option value="Security Testing">Security Testing</option>
            </Form.Select>
          </Form.Group>

          <div className="browser-select d-flex mt-3">
            <div className="mt-3">
              <Form.Label htmlFor="basic-url" className="create-label">
                Select Browser / Mobile OS
              </Form.Label>
              <div className="browser-icon d-flex"
                onClick={handleClick}
                onKeyDown={(e) => { 
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleClick();
                  }
                }}
                style={{ opacity: selectImg ? "0px" : "0.3px" }}
              >
                <img
                  src={chrome}
                  style={{ cursor: "pointer" }}
                  alt="chrome icon"
                  onClick={handleClick}
                  onKeyDown={(e) => {       // Handles keyboard events
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleClick();
                    }
                  }}
                // style={{ opacity: selectImg ? '0px' : '0.3px' }}
                />
                <img
                  src={safari}
                  style={{ cursor: "pointer", opacity: '0.5' }}
                  alt="safari icon"
                />
                <img
                  src={firefox}
                  style={{ cursor: "pointer", opacity: '0.5' }}
                  alt="firefox icon"
                />
                <img src={edge} style={{ cursor: "pointer", opacity: '0.5' }} alt="edge icon" />
                <img
                  src={apple}
                  style={{ cursor: "pointer", opacity: '0.5' }}
                  alt="apple icon"
                />
                <img
                  src={android}
                  style={{ cursor: "pointer", opacity: '0.5'}}
                  alt="android icon"
                />
              </div>
            </div>
            <div className="browser-version ms-4 mt-3">
              <Form.Label htmlFor="basic-url" className="browser-label">
                Select Version
              </Form.Label>

              <Form.Group>
                <Form.Select
                  className="CSH-version-select d-flex"
                  value={version}
                  onChange={handleVersion}
                  required
                >
                  <option value="Chrome 100">Chrome 100</option>
                  <option value="Chrome 99">Chrome 99</option>
                  <option value="Chrome 98">Chrome 98</option>
                  <option value="Chrome 97">Chrome 97</option>
                  <option value="Chrome 96">Chrome 96</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          {/* <Form.Group className="mt-3" value={environment}>
            <Form.Label className="CSH-edit-label">
              Assign StakeHolders
            </Form.Label>

            <Multiselect className="CSH-stake-select"
              displayValue="mail"
              // onKeyPressFn={function noRefCheck() { }}
              // onRemove={function noRefCheck() { }}
              // onSearch={function noRefCheck() { }}
              onSelect={function noRefCheck(e) {
                arrList = e
                // setSelectedList([...e, ...selectedList])
              }}
              options={[...scheduleStake]}
              showCheckbox
            />
          </Form.Group> */}

          <div className="CSH-Date mt-3 d-flex w-100">
            <div className="CSH-DP-head">
              <Form.Label htmlFor="basic-url" className="CSH-datePicker">
                Start Date
              </Form.Label>

              <div className="CSH-DP-input mr-2">
                <Form.Control type="date" id="date" name="date" className="CSH-form"
                  onChange={(event) => setDate(event.target.value)}
                />
              </div>
            </div>

            <div className="CSH-FR-head d-flex">
              <Form.Group className="w-100">
                <Form.Label htmlFor="basic-url">Frequency</Form.Label>
                <Form.Select
                  className="CSH-cr-version-select d-flex"
                  value={frequency}
                  onChange={handleFrequency}
                >
                  <option value="" disabled>Select Frequency</option>
                  <option value="Daily">Daily</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <div className="FR-sec d-flex">
            <Form.Control type="time" id="time" name="time" className="CSH-time"
              onChange={(event) => setTime(event.target.value)}
            />

            {showDay && <Form.Group>
              <Form.Select
                className="CSH-day-version-select d-flex "
                value={day}
                onChange={handleDay}
              >
                <option value="" disabled>select a day</option>
                <option value="Sunday">Sunday</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="thursday">thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
              </Form.Select>
            </Form.Group>}

            {/*Select session if chose month */}

            {ShowSession && <Form.Group>
              <Form.Select
                className="CSH-sess-version-select d-flex"
                value={session}
                onChange={handleSession}
              >
                <option value="" disabled>select a session</option>
                <option value="Start of Month">Start of Month</option>
                <option value="End of Month">End of Month</option>
              </Form.Select>
            </Form.Group>}
          </div>

          <div className="CSH-suite-buttons justify-content-end ml-3">
            <button className="save-CSH" onClick={() => postSchedule()}>Schedule</button>
            <button className="CSH-cancel-ts" onClick={()=> {
              handleClose();
              setDate("")
              setFrequency("")
              setEnvironment("")
              setVersion("")
            //   setTestsuite_id("")
            //   setScheduleStake([])
              setShowDay("")
              setSelectDay("") 
              setShowDay(false)
              setShowSession(false)
              setTime("")
              SetSelectSession("")
              }}>
              Cancel
            </button>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
        </>
    );
}

export default function ScheduleTestsuite({ getSchedule, id }) {

    return (
        <>
            {options.map((props, idx) => (
                <OffCanvasExample
                    key={idx}
                    // getApp={getSchedule}
                    scheduleId={id}
                    {...props}
                />
            ))}
        </>
    );
}
