import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import headerImg from "./../../../assets/Icons/headerImg.svg";
import "./Navbar.css";
import Sidebar from "../Sidebar/Sidebar";
import profile from "./../../../assets/Icons/Profile.svg";
// import linksearch from "./../../../assets/Icons/Search.svg";
import { useMsal } from "@azure/msal-react";
import SearchIcon from "@mui/icons-material/Search";

//Using here for new model Navbar
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { API } from "../../../global";
import { useNavigate } from "react-router";
import jwt_decode from "jwt-decode";
// import { useEffect } from "react";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
// import { Col } from "react-bootstrap";

const Main_navbar = (props) => {
  // const dispatch = useDispatch();

  const { accounts, inProgress } = useMsal();
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  // const userName = getFromStorage("username");

  // const reduxUserName = useSelector(state => state?.commonLogin?.username);

  // const HandleChange = (e) => {
  //     const {name, value} = e?.target;
  //     setDepartMent(value);
  // };

  const { instance } = useMsal();

  const handleLogout = () => {
    localStorage.removeItem("azure-token");
    localStorage.removeItem("user-id");
    localStorage.removeItem("testcase-id");
    localStorage.removeItem("role");
    localStorage.removeItem("access-key");
    // localStorage.removeItem("project-id");
    localStorage.removeItem("role-name");
    if(localStorage.getItem('type')==='basic_auth'){
      localStorage.clear();
      navigate('/login')
    }else{
      localStorage.clear();
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRole_value(role)
  }
  const handleNavigate = (val) => {
    localStorage.setItem("role-name", role_value);
    setShow(false);
    window.location.href = `${window.location.protocol}//${window.location.host}/${role_value ==='Admin' ? role_value : 'qe'}/dashboard`;
  };
  

  const roles = JSON.parse(localStorage.getItem("role"));

  const role = localStorage.getItem("role-name");
  const [role_value, setRole_value] = useState('');
  const [pro_nav, setPro_nav] = useState("");
  const handleChange = (value) => {
    setRole_value(value);
  };
  const handleShow = () => {
    setRole_value(role)
    setShow(true)
  };

  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState('');
  const [project_selected,setProject_selected] = useState("");
  const [report, setReport] = useState("");
  const [tab,setTab] = useState("");
  const project_id = localStorage.getItem("project-id");
  const project_name = localStorage.getItem("pro-name");
  const user_name = localStorage.getItem("user_name");
  const current_role = localStorage.getItem('current_role');
  const getProject = async () => {
    const userId= localStorage.getItem('user-id')
    await fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => {
        let projectData = [...pro.result.data];
        console.log(projectData,'project');
        let not_empty=[];
        let userList = [];
        let assign_project_list = []
        projectData.forEach((d,i)=>{
        if(d.project_users.length !==0){
          not_empty.push({i:i, ...d})
         }
        })
        not_empty.forEach((a)=>{
           userList.push(a.project_users)
        })
         const assign_project = userList.flat().filter(d=>d.user_id===userId);
        projectData.forEach((d)=>{
         if(assign_project.some(a=>a.project_id===d._id)){
           assign_project_list.push(d)
         }
        })
          //pro-name
          if(assign_project_list.some(d=> d._id===localStorage.getItem('project-id'))){

          }else{
            localStorage.removeItem('pro-name');
            localStorage.removeItem('project-id')
          }
        setProjects(assign_project_list.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      });
  };

  const token = localStorage.getItem("access-key");

  const runLogoutTimer = (timer) => {
    setTimeout(() => {
      toast.error("Session Expired");
      setTimeout(() => {
        localStorage.removeItem("azure-token");
        localStorage.removeItem("user-id");
        localStorage.removeItem("testcase-id");
        localStorage.removeItem("role");
        localStorage.removeItem("access-key");
        // localStorage.removeItem("project-id");
        localStorage.removeItem("role-name");
        navigate("/");
        sessionStorage.clear();
        window.location.reload();
        // handleLogout();
      }, 3000);


    }, timer);
  };
  useEffect(() => {

    if(inProgress && accounts.length > 0)
    {
      const account = accounts[0];
      setUserName(account.name); 
      localStorage.setItem('username', account.name);
    } else {
      const storedUser = localStorage.getItem('username');
      if (storedUser) {
        setUserName(storedUser);
      }
}
    getProject();
    let url = "";
    let url_split = [];
    url = window.location.href;
    url_split = url.split("/");
    setRole_value(url_split[3]);
    setPro_nav(url_split[4]);
    setReport(url_split[5])
    setTab(url_split[6])
    if (token) {
      var decoded = jwt_decode(token);
      const date_to = new Date(decoded.exp * 1000);
      const cur_date = new Date(Date.now());
      runLogoutTimer(date_to.getTime() - cur_date.getTime());
    }
   
    if(localStorage.getItem('count')==='1'){
      localStorage.setItem('count','2');
      setTimeout(()=>{
        window.location.reload();
      },1000)
    }
    // eslint-disable-next-line
  }, [token,accounts, inProgress]); 

  const getApplication = (value) => {
    fetch(
      `${API}/admin/application/${value}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    )
      .then((val) => val.json())
      .then((app) => {
        // console.log("app",app.data)
        app.data.length > 0 && localStorage.setItem("application_id", app.data[0]._id);
      })

  };
  const handleInputCHnage =(e)=>{
    setSearch(e.target.value);
    console.log(e.target.value);
    props.testcaselist(e.target.value)
  }

  const handleSelect=(a,e)=>{
    localStorage.removeItem("suite_id")
    localStorage.removeItem("suite_name")
    let name = projects.filter((pro)=>pro._id === a )
    setProject_selected(name[0].name)
    localStorage.removeItem('app_id')
    localStorage.removeItem('app-id')
    localStorage.setItem("project-id", a);
    localStorage.setItem("pro-name",name[0].name)
       getApplication(a)
       tab === "table" && navigate(
        `/qe/testsuite/report/table/${a}`
       )
     report === "reports" ?
                      navigate(
                        `/${role_value}/${pro_nav}/reports/${a}`
                      ) :
                      report === "runlog" ?
                        navigate(
                          `/${role_value}/${pro_nav}/runlog/${a}`
                        ) :
                        tab === "table" ?
                        navigate (
                        `/qe/testsuite/report/table/${a}` 
                        )
                        :
                        navigate(
                          `/${role_value}/${pro_nav}/pr/${a}`
                        ) 
                        window.location.reload()
  }
  return (
    <>
      <Sidebar />
      <Navbar bg="light" expand="sm" className="Navbar-head fixed-top">
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <Container fluid className="main_nav align-middle">
          <Navbar.Brand className="navbar-brand">
            <img
              src={headerImg}
              className="head-image"
              alt="Ignitho Quality Accelerator"
            />
          </Navbar.Brand>
          <Nav>
            <div className="d-flex ms-2">
              <Form className="search-box mt-1">
                <div className="search-icon mt-4 px-1 align-middle">
                  <SearchIcon className="search-icon-size" />
                </div>
                <Form.Control
                  aria-label="Search"
                  className="head-search px-4"
                  type="search"
                  placeholder="Search"
                  value={search}
                  onKeyDown={(e)=>{
                    if(e.key === 'Enter') {
                     e.preventDefault();
                       }}}
                  onChange={(e)=>handleInputCHnage(e)}
                />
              </Form>
            </div>


            <div className="d-flex nav-opt">
              <Nav.Link>
                <label className="dept-lab position-absolute ms-2 mb-1">Department</label>

                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="dept">
                    {/* TODO: remove this hack and add - - Select - -, need to be fixed > */}
                  HR
                  </Dropdown.Toggle>
                  <div className="nvbar_Sh">
                    <Dropdown.Menu className="qe-nv-drop-menu text-center">
                      <Dropdown.Item value={"HR"}>HR</Dropdown.Item>
                      <Dropdown.Item value={""}>Product</Dropdown.Item>
                      <Dropdown.Item value={""}>QA</Dropdown.Item>
                      <Dropdown.Item value={""}>Testing</Dropdown.Item>
                      </Dropdown.Menu>
                  </div>
                </Dropdown>
              </Nav.Link>
        
              <Nav.Link>
                <label className="proj-lab position-absolute ms-2 mb-1">Project</label>
                <Dropdown onSelect={handleSelect}>
                <Dropdown.Toggle id="dropdown-basic" className="dept" >
                {(project_selected && project_selected.length > 10) ? `${project_selected.slice(0, 10)}...` :(project_selected && project_selected.length <= 10) ? project_selected:(project_name && project_name.length > 10) ? `${project_name.slice(0, 10)}...`: (project_name && project_name.length <= 10) ? project_name : "- - Select - -"}
                </Dropdown.Toggle>
                <div className="nvbar_Sh">
                <Dropdown.Menu className="qe-nv-drop-menu text-center" id="project"
                      >
                {projects.map((res, i) => {
                    return (
                      <Dropdown.Item
                        key={i}
                        className="val"
                        selected={res._id === project_id ? true : false}
                        style={{ color: "black" }}
                        eventKey={res._id}
                        value={res._id}
                      >
                        {res.name}
                      </Dropdown.Item>
                    );
                  })} 
                </Dropdown.Menu> 
                </div>
                </Dropdown>
              </Nav.Link>
            </div>

          </Nav>
          {/*          
          <div className="profile" >
            <div className="p-name">
              <div className="user-text"></div>
              <div className="role-text">(Role: {role[0]})</div>
            </div>
            <div className="profile-pic ml-5">
              <img src={profile} alt="profile icon" />
            </div>
          </div>  */}
          <div className="dropdown">
            <div
              className="dropdown-toggle d-flex gap-2"
              style={{ cursor: "pointer" }}
              data-bs-toggle="dropdown"
            >
              <span>
                {userName ? userName : user_name}
                <div>(Role: {role ? role :current_role})</div>
              </span>
              <div>
                <img src={profile} alt="profile icon" />
              </div>
            </div>
            <div className="dropdown-menu overflow-hidden">
              <div className="nvbar_Sh text-center">
                {roles.length > 1 ? (
                  <div
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={handleShow}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleShow();
                    }
                  }}
                >
                  Choose Role
                </div>
                
                ) : (
                  ""
                )}
                <div
  className="dropdown-item"
  style={{ cursor: "pointer" }}
  onClick={() => handleLogout()}
  tabIndex={0}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleLogout();
    }
  }}
>
  LogOut
</div>

              </div>
            </div>
          </div>
        </Container>
      </Navbar>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose a role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {roles.map((val, i) => {
              return (
                <div>
                  {" "}
                  <input
                    type="radio"
                    checked={role_value === val ? true : false}
                    onChange={() => handleChange(val)}
                    value={role_value}
                    key={i}
                  />{" "}
                  {/* TODO: remove this hack and add a display name entry in role table > */}
                  {val === 'Admin'? "Admin": null }
                  {val === 'Quality Engineer'? "Quality Engineer" : null }
             
                </div>
           
              );
                   
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="" onClick={() => handleNavigate()}>
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Main_navbar;
