import React from 'react'
import Pagination from '@mui/material/Pagination';
//import { makeStyles } from '@material-ui/core/styles';
import "./Paginate.css"

// const useStyles = makeStyles(() => ({
//     ul: {
//       "& .MuiPaginationItem-root": {
//         borderColor: "black" 
//       }
//     }
//   }));

function Paginate() {
 //   const classes = useStyles();
    return (
        <div className='page-head fixed-bottom'>
            <Pagination count={10} variant="outlined" shape="rounded"/>
        </div>
    )
}

export default Paginate