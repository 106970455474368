import React from "react";
// import OverviewAdmin from "../../../pages/Overview/Admin/OverviewAdmin";
import OverviewAdmin from "../../DashboardComponents/Overview/Admin/OverviewAdmin";
// import Statistics from "../../../pages/Statistics/Statistics";
import AdminStatistics from "../../DashboardComponents/Statistics/Admin/AdminStatistics";
// import Summary from "../../../pages/Summary/Summary";
import AdminSummary from "../../DashboardComponents/Summary/Admin/AdminSummary";
import AdminTopgreen from "../../DashboardComponents/Topgreen/Admin-Topgreen/AdminTopgreen";
import AdminSidebar from "../../Admin/AdminSidebar/AdminSiderbar";
//import Header from "../../Layout/Navbar/Navbar";
import AdminNavbar from "../../Admin/AdminNavbar/AdminNavbar"
import "./DashboardAdmin.css";
import { AiOutlineInfoCircle } from "react-icons/ai";

function DashboardAdmin() {
  const project_id = localStorage.getItem("project-id");
  return (
    <div className="admin-dashboard">
     <AdminNavbar/>
      <AdminSidebar />
      {project_id ? (
        <div className="admin-overview">
          <div className="admin-dash-head">
            <AdminTopgreen />
              <OverviewAdmin />
              <AdminSummary />
             <AdminStatistics />
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "19%", marginLeft: "45%" }}>
          <div style={{ marginLeft: "10%" }}> <AiOutlineInfoCircle size="30px" color="#9F9F9F" /></div>
          <h6 style={{ color: "#9F9F9F" }}>Please Select a Project</h6>
        </div>
      )}
    </div>
  );
}

export default DashboardAdmin;
