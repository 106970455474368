export const msalConfig = {
  auth: {
    clientId: "00ba2f42-c0be-4c9b-9395-1a5e032c55d4",
    authority:
      "https://login.microsoftonline.com/04527940-52a6-4f78-9bc4-253f9546b748", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // redirectUri: "http://localhost:3000/dashboard",
    redirectUri: `${window.location.protocol}//${window.location.host}/qe/dashboard`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com",
};
