import { API } from "../../../global";
import { useNavigate } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../Layout/Navbar/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { SelectionText } from "@uiw/react-textarea-code-editor";
import "./GenerateTestcase.css";

export default function GenerateTestcase() {
  const [generateStory, setGenerateStory] = useState([]);
  const [userstory, setUserstory] = useState("");
  const [userdata, setUserdata] = useState([]);
  const [found,setFound] = useState(false)
  const testcaseId = localStorage.getItem("testcase-id");
  const navigate = useNavigate()

  const handleClose = () => {
    setUserstory("")
    setFound(false)
    setUserdata([]);
  };
  const clear_gen = () => {
    setGenerateStory([]);
  }

  const generateTestcase = async (userstory) => {
    const response = await fetch(`${API}/tests/testcases/generation`, {
      method: "POST",
      body: JSON.stringify({ user_story: userstory }),
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
    const value = await response.json();
    setGenerateStory(value.data);
    if (value.data.length === 0){
      setFound(true)
    }
  };

  const create_testcase = () => {
    const testcase = {
      created_by: localStorage.getItem("user-id"),
      updated_by: localStorage.getItem("user-id"),
      test_case_type: type,
      project_id: projectId,
      application_id: applicationId,
      user_story: userstory,
      test_case_name: userdata,
      test_case_description: userdata,
      last_run_status: "Pending",
      test_case_id: testcase_arr,
    };
    // console.log("test",testcase)
    fetch(`${API}/tests/testcases`, {
      method: "POST",
      body: JSON.stringify(testcase),
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then(() => navigate("/qe/testcase"))

  };
  const testcase_arr = [];
  for (let i = 1; i <= userdata.length; i++) {
    testcase_arr.push(+testcaseId + i);
  }
  const checkboxData = (event) => {
    let permissions_array = [...userdata];
    if (event.target.checked) {
      permissions_array = [...userdata, event.target.value];
    } else {
      permissions_array.splice(userdata.indexOf(event.target.value), 1);

    }
    setUserdata(permissions_array);
  };

  const textRef = useRef();
  const [projectlist, setProjectlist] = useState([]);
  const [applist, setApplist] = useState([]);
  const [type, setType] = useState("Functional");
  const [projectId, setProjectId] = useState("");

  const [applicationId, setApplicationId] = useState("");


  const pro_id = localStorage.getItem('project-id')
  const app_id = localStorage.getItem('app_id')

  useEffect(() => {

    if (textRef.current) {
      const obj = new SelectionText(textRef.current);
      console.log(obj);
    }
    if (pro_id) {
      getApplication(pro_id)
      setProjectId(pro_id)
    }
    if (app_id) {
      setApplicationId(app_id)
    }
    getProjects();
    // eslint-disable-next-line
  }, []);

  const handleTestcaseType = (event) => {
    setType(event.target.value);
  };

  const handleProjectId = (event) => {
    setProjectId(event.target.value);
    getApplication(event.target.value);
  };
  const handleApplicationId = (event) => {
    setApplicationId(event.target.value);
  };

  const getApplication = async (val) => {
    setApplist([]);
    await fetch(`${API}/admin/application/${val}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {
        app.result.data.length > 0 ? setApplist(app.result.data.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : setApplist([]);
        app.result.data.length === 0 ? setApplicationId('') : setApplicationId(app.result.data[0]._id);
      });
  };
  const getProjects = async () => {
    const userId= localStorage.getItem('user-id')
    await fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => {
        let projectData = [...pro.result.data];
        console.log(projectData,'project');
        let not_empty=[];
        let userList = [];
        let assign_project_list = []
        projectData.forEach((d,i)=>{
        if(d.project_users.length !==0){
          not_empty.push({i:i, ...d})
         }
        })
        not_empty.forEach((a)=>{
           userList.push(a.project_users)
       
        })
         const assign_project = userList.flat().filter(d=>d.user_id===userId);
        projectData.forEach((d)=>{
         if(assign_project.some(a=>a.project_id===d._id)){
           assign_project_list.push(d)
         }
        })
        // console.log(pro.data);
        setProjectlist(assign_project_list.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        // pro.data.length > 0 ? setProjectId(pro.data[0]._id) : setProjectId();
      })
  }

  return (
    <>
      <div className="gentestcase-create">
        <Header />
        <div>
          <Container fluid>
            <Row className="gen-test-row">
              <Col sm={5}>
                <div className="d-flex first-head justify-content-start">
                  Test Cases
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <Container fluid className="mt-4">
            <Row>
              <Col>
                <div className="gen-sec-head d-flex justify-content-start">
                  Generate Test Case
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container fluid className="mt-4">
          <Row>
            <Col sm={4}>
              <div className="justify-content-center text-start">
                <Form className="gen-user-story-menus">
                  <Form.Group>
                    <Form.Label>Select Project</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Select Project"
                      className="gen-form mb-3"
                      required
                      value={projectId}
                      onChange={handleProjectId}
                    >

                      {projectlist.map((data, i) => (
                        <option
                          selected={data._id === projectId ? true : false}
                          value={data._id}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Select Application</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Select Application"
                      className="gen-form mb-3"
                      required
                      value={applicationId}
                      onChange={handleApplicationId}
                    >
                      {applist.length === 0 && <option>No Application found</option>}
                      {applist.map((data, i) => (
                        <option
                          key={i}
                          selected={data._id === applicationId ? true : false}
                          value={data._id}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Test Case Type</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Select Test Case Type"
                      className="gen-form mb-3"
                      required
                      value={type}
                      onChange={handleTestcaseType}
                    >
                      <option value="Functional">Functional</option>
                      <option value="System">System</option>
                      <option value="Performance">Performance</option>
                      <option value="UI">UI</option>
                      <option value="Backend">Backend</option>
                    </Form.Select>
                  </Form.Group>
                </Form>
              </div>
            </Col>
            <Col sm={8}>
              <div className="justify-content-center ms-5 text-start bg-gray">
                <form>
                  <Form.Label className="genlabel">User Story</Form.Label>
                  <br />
                  <span className="gen-box">
                    <input
                      type="text"
                      id="test-input"
                      value={userstory}
                      required
                      className="-mt-4 genInput form-control"
                      placeholder="Enter Userstory"
                      onChange={(event) => {
                        setUserstory(event.target.value);
                      }} />

                    <button
                      id="submit-button"
                      type="submit"
                      pys-onclick="my_function"
                      size="sm"
                      className="gen-button generate-publish"
                      onClick={(e) => {
                        e.preventDefault()
                        generateTestcase(userstory)
                      }}>Generate</button>
                    <button
                      size="sm"
                      type="cancel"
                      className="clear-button"
                      onClick={() => handleClose()}>Clear</button>
                  </span>
                </form>
                {generateStory.length !== 0 ? <div className="justify-content-center ms-1 text-start mt-2">

                  <Form.Label className="mb-2 genlabel">Testcases</Form.Label><br />
                  <div>
                    <div
                      className="form-control"
                      style={{
                        overflowY: "scroll",
                        height: "230px",
                      }}
                    >
                      {generateStory.map((testcase, i) => (
                        <h6 style={{ listStyle: "none" }} key={i}>
                          <input
                            type="checkbox"
                            value={testcase}
                            onChange={(e) => checkboxData(e)}
                            style={{ margin: "5px", zoom: "1.5" }}
                          />
                          <span>{testcase}</span>
                        </h6>
                      ))}

                      <br />
                    </div>
                    <div className="save-button">
                      <button
                        id="submit-button"
                        variant="normal"
                        pys-onclick="my_function"
                        size="sm"
                        className="gen-button"
                        onClick={() => create_testcase()}
                        disabled={userdata.length === 0 ? true : false}
                      >
                        Save
                      </button>
                      <button
                        variant="normal"
                        size="sm"
                        type="cancel"
                        className="clear-button"
                        onClick={() => clear_gen()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div> : found ?
                <div className="justify-content-center ms-1 text-start mt-2">
                   <h6>No testcases found</h6>
                </div> : ""}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
