import React, { useState } from 'react';
import './Freemium.css';
import heading from '../../assets/img/heading.png';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom'; 
import warning from '../../assets/img/warning.png';
import { API } from '../../global';
import { toast } from "react-toastify";
import { BsEye, BsEyeSlash } from "react-icons/bs";


const FreemiumLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [loading, setLoading]=useState(false)
  const [showPass, setShowpass]=useState(true)
  const [logError, setLogError] = useState('')
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);


  const togglePassword = () => {
    if(email){
      setLoading(true)
      fetch(`${API}/users/verify`, {
        method: "POST",
        body: JSON.stringify({
          email
        }),
        headers: {
          "content-type": "application/json",
        },
      })
      .then((a) => a.json())
      .then((b)=>{
        if(b.info.msg==="Request success" && b.result.user !== null){
          localStorage.setItem('type',b.result.user.auth_type )
          if(b.result.user.auth_type==="singleSignOn"){
            navigate('/ms/login')
          }else{
            if(b.result.user.verify){
              localStorage.setItem('regEmail',email)
              setShowPassword(!showPassword);
            }else{
              toast.error('Please Verify before Login ')
            }
            
          }
        
          // toast.success(b.info.msg);
        }else if(b.info.msg==="Tenant Not Found, please contact admin"){
          localStorage.setItem('regEmail',email)
          setShowModal(true);
        }else if(b.result.user=== null ){
          localStorage.setItem('regEmail',email)
          setShowModal(true);
        }else{
          toast.error(b.info.msg);
        }
        setLoading(false)
      })
        .catch((err) => {
          console.log(err);
          setLoading(false)
  
        });
  
    }else{
      toast.error("value must not be Empty ")
    }

     };

  const handleLog = () =>{
    if(email && password){
      fetch(`${API}/users/login`, {
        method: "POST",
        body: JSON.stringify({
          email,
          password
        }),
        headers: {
          "content-type": "application/json",
        },
      })
      .then((a) => a.json())
      .then((b)=>{
        if(b.info.msg==="User successfully logged in"){
          localStorage.setItem("access-key", b.result.token);
          localStorage.setItem("role", JSON.stringify(b.result.data.role_name));
          localStorage.setItem("user_name", b.result.data.Ad_username)
          localStorage.setItem("user-id", b.result.data._id)
          toast.success(b.info.msg);
          console.log(b.result.data.role_name);
          navigate_user(b.result.data.role_name);
        }else if(b.info.msg==="Sorry, user not found"){
          toast.error(b.info.msg);
          setShowModal(true);
        }else{
          toast.error(b.info.msg);
        }
        
      })
        .catch((err) => {
          console.log(err);
        });
    }else{
      toast.error("value must not be Empty ")
    }

 
  }
  const togglePasswordVisibility = () => {
      setVisiblePassword(!visiblePassword);
  };

  const navigate_user = (user) => {
   
    // if (user.length === 1) {
      localStorage.setItem("current_role", user[1])
      localStorage.setItem("role-name", user[1])
      navigate(`/${user[1]==="Admin" ? "admin" : "qe"}/dashboard`);
      window.location.reload();
    // }
  };



  const handleClick = (a) => {
    if(a==='pop'){
      navigate("/register");

    }else{
      localStorage.removeItem('regEmail')

      navigate("/register");

    }

  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-5 leftbanner d-flex align-items-center justify-content-center">
          <div className="banner-main d-flex flex-column justify-content-center align-items-center">
            <img src={heading} alt="title" className="img-fluid w-50" />
            <p className="text-center w-75 mt-5 text-white">It is the ultimate solution for organizations looking to automate their Testcases, test suites and unlock the full potential of AI-driven testcases creation. With its intuitive interface, powerful capabilities, and unrivalled performance, Ignitho quality accelerator empowers businesses to deliver exceptional service.</p>
          </div>
        </div>

        <div className="col-7 rightBanner d-flex align-items-center justify-content-center">
          <Form className="w-50 h-auto pb-5 form-main bg-white rounded" onSubmit={e => { e.preventDefault()}}>
          <div className="sub-main d-flex align-items-center justify-content-start flex-column">

            <div className="sub-main d-flex w-50 align-items-center justify-content-start flex-column">
              <h5 className="forms-login text-center mt-4">Login</h5>
              <Form.Group className="mb-3 w-100 email-input">
                <Form.Label className="fw-bold fs-6">E-Mail ID</Form.Label>
                <Form.Control size="sm" type="email" placeholder="Enter your E-mail ID" onChange={(e)=>{
                  setEmail(e.target.value)
                  let re = /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                  if(re.test(e.target.value)){
                    setLogError("")
                    setShowpass(false)
                  }else{
                    setShowpass(true)
                    setLogError("Please Enter Bussiness email")
                  }
                  if(e.target.value.length>0){
   
                  }else{
                    toast.error("value must not be Empty ")
                  }
                  }} value={email}/>
              </Form.Group>
              {logError &&<div className="user-error">
              <img src={warning} alt="error" />{logError}
              </div>}

                            {showPassword && (
                
                  <Form.Group className="mb-3 w-100 pass-input">
                    <Form.Label className="fw-bold fs-6">Password</Form.Label>
                    <div className='custom_icon'>
                  <div className='input_icon'>
                    <Form.Control size="sm" 
                    type={visiblePassword ? "text" : "password"} 
                    placeholder="Enter your password"
                     onChange={(e) => setPassword(e.target.value)} value={password}/>
                      </div>
                  <div className='input_eye_login' onClick={()=>togglePasswordVisibility()}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          togglePasswordVisibility();
                      }
                  }}
                    >
                  {visiblePassword ? <BsEye /> : <BsEyeSlash />}
                   </div>
                  </div>
                     {/* {logError &&<div className="user-error d-flex align-items-center justify-content-start gap-2">
              <img src={warning} alt="error" />{logError}
              </div>} */}
                  </Form.Group>
               
              )}
              
              
              <div className="bttns d-flex gap-5">
              
                <button type="button" className="btn-sm py-1 px-4 mt-1 log-btn" disabled={showPass} onClick={showPassword ? handleLog : togglePassword}>{loading ? '...Loading' : 'Login'}</button>
                
                {/* <button type="button" className="btn btn-sm w-50 log-btn" onClick={msLogin}>MS Login</button> */}
                
              </div>

              {!showPassword && (
               
                <div>
                 <p className="fs-6 mt-1 mb-2 text-center">or</p>
                  <h6 className="text-black fs-9 font-weight-bold">Register your account</h6>
                  <div className="reg-btn text-center">
                  <button type="button" className="btn-sm py-1 px-4 mt-1" onClick={handleClick}>Register</button></div>
                </div>
              )}
            
            </div>
            </div>
          </Form>
        </div>
      </div>

      {/*Login error modal */}

      
      <div className="modal" tabIndex="-1" role="dialog" style={{ display: showModal ? 'inline-block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h5>You're not registered with us !!!</h5>
              <p>Would you like to create an account on Ignitho Quality Accelerator ?</p>
              <div className="modal-btn gap-4 d-flex justify-content-center">
              <button type="button" className="btn px-2 py-1 not" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}>Not Now</button>
              <button type="button" className="btn px-3 py-1 create" onClick={()=>handleClick('pop')}>Create</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreemiumLogin;
