import React from 'react';
import verify from "../../assets/img/verify.png";
// import union from "../../assets/img/Union.png"; 
// import Form from 'react-bootstrap/Form';
// import FreemiumBg from "../../components/Freemium/FreemiumBg";
// import { toast } from "react-toastify";
// import { API } from '../../global';
// import { useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";





const MailBanner = () => {
    // const [showPass, setShowPass] = useState(false);
    // const [newPassword, setNewPassword] = useState('');
    // const [cnfmPassword, setCnfmPassword] = useState('')
    // const{auth}=useParams();
    // const navigate = useNavigate();
    // const handleClick = () =>{
    //     setShowPass(!showPass)
    // }
 
  return (
    <div>

    <div className="mail-banner d-flex justify-content-center align-items-center flex-column">
    <img src={verify} alt="mail" className="mail-img mb-3" />
    <h6 className="mail-title fw-bold mb-3">Please verify your email</h6>
    <p className="mail-para text-center w-75">Thank you for registering! <br/>An email has been sent to the provided email address. Please verify your email to complete the registration process.</p>
    </div> 

    </div>
  )
}

export default MailBanner