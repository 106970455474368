import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import "chartjs-plugin-annotation";
import "./Statistics.css";
import UpcomingTest from "../UpcomingTest/UpcomingTest";
import { API } from "../../../global";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
// import TestsuiteList from "../../TestSuite/TestsuiteList/TestsuiteList";
// import { useState } from "react";
// import TestCase from "../TestCase/TestCase";

Chart.register(CategoryScale);



function Statistics() {
  const [testsuiteData, setTestsuiteData] = useState([]);

  // let monthNames = testsuiteData.map((test)=> test.name);



  const {context} = useParams();
  
let monthNames = [
  "Jan, 2024",
  "Feb, 2024",
  "Mar, 2024",
  "April, 2024",
  "May, 2024",
  "Jun , 2024",
];



let line = [
  {
    type: "line",
    mode: "vertical",
    scaleID: "y-axis-0",
    value: 2000,
    borderColor: "#2984c5",
    borderWidth: 1,
  },
];

const options = {
  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxWidth: 12,
      },
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          console.log(typeof context,'context'); 
          let label = "";
          if(context.formattedValue==='0'){
            label = `0 ${context.dataset.label}`;
          }else if (context.parsed.y) {
            label = (context.dataset.label ==='Test Suites' || context.dataset.label === 'Test Cases') ? ` ${context.parsed.y} ${context.dataset.label}`:`${context.parsed.y}% ${context.dataset.label}`
          }
          return label;
        },
      },
      usePointStyle: true,
      boxWidth: 8,
      displayColors: true,   
      backgroundColor: "#5D3B5E",
      mode: "index",
      intersect: false,
      caretSize: 6,
      yPadding: 10,
      xPadding: 20,
      borderWidth: 0,
      titleFontSize: 16,
    },

    annotation: {
      annotations: line,
    },

    responsive: false,
  },
  scales: {
    y: {
      ticks: {
        display: false,
      },
      grid: {
        display: true,
        borderDash: [8, 4],
        drawBorder: false,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
};
 
  // const [testcaseData, setTestcaseData] = useState({});

  const project_id = localStorage.getItem("project-id");
  const application_id = localStorage.getItem("app_id")


  useEffect(() => {
    getTestsuiteLogs();
    // getTestcaseLogs();
    // eslint-disable-next-line
  }, [context]);


  const getTestsuiteLogs = () =>{
    console.log(project_id, application_id,'aaaa');
    fetch(project_id && application_id === null ? `${API}/tests/testsuite/statistics/?project_id=${project_id}` :`${API}/tests/testsuite/statistics/?project_id=${project_id}&application_id=${application_id}` ,{
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
     }).then((data) => data.json())
    .then((val)=>{
      console.log(val.result.testcase.map(d=> {return d.passedCases}),"testsuite")

       val.result.testcase.length > 0 ? setTestsuiteData(val.result.testcase) : testsuiteData([])
    } )
  }


  return (
    <>
      <div className="tsr-head_title">
        <span className="col" style={{marginRight:"10px"}}>Statistics</span>
        <span className="col">Upcoming Test Schedule</span>
      </div>
      <div className="row-stat">
        <div className="col line_chart">
          <Line data={{
       labels: [...monthNames],
      datasets: [
    {
      fill: false,
      label: "Passed",
      lineTension: 0.2,
      backgroundColor: "#7EB965",
      borderColor: "#7EB965",
      borderWidth: 1,
      pointBorderColor: "#7EB965",
      pointBackgroundColor: "#7EB965",
      pointHoverRadius: 1,
      pointHoverBackgroundColor: "#7EB965",
      pointHoverBorderColor: "#7EB965",
      pointHoverBorderWidth: 7,
      pointRadius: 0,
      pointHitRadius: 30,
      data: testsuiteData.map(d=> {return d.passedCases})
        // testsuiteData.map
        // testsuiteData[0].passedCases ===undefined ? 0 :testsuiteData[0].passedCases,
        // testsuiteData[1].passedCases ===undefined ? 0 :testsuiteData[1].passedCases,
        // testsuiteData[2].passedCases ===undefined ? 0 :testsuiteData[2].passedCases,
        // testsuiteData[3].passedCases ===undefined ? 0 :testsuiteData[3].passedCases,
        // testsuiteData[4].passedCases ===undefined ? 0 :testsuiteData[4].passedCases,
        // testsuiteData[5].passedCases ===undefined ? 0 :testsuiteData[5].passedCases
      ,
    },

    {
      fill: false,
      label: "Failed",
      lineTension: 0.2,
      backgroundColor: "#DC3545",
      borderColor: "#DC3545",
      borderWidth: 1,
      pointBorderColor: "#DC3545",
      pointBackgroundColor: "#DC3545",
      pointHoverRadius: 1,
      pointHoverBackgroundColor: "#DC3545",
      pointHoverBorderColor: "#DC3545",
      pointHoverBorderWidth: 7,
      pointRadius: 0,
      pointHitRadius: 30,
      data:testsuiteData.map(d=> {return d.failedCases})

      // testsuiteData[0].failedCases,
      // testsuiteData[1].failedCases,
      // testsuiteData[2].failedCases,
      // testsuiteData[3].failedCases,
      // testsuiteData[4].failedCases,
      // testsuiteData[5].failedCases
    ,
    },
    {
      fill: false,
      label: "Test Suites",
      lineTension: 0.2,
      backgroundColor: "#805382",
      borderColor: "#805382",
      borderWidth: 1,
      pointBorderColor: "#805382",
      pointBackgroundColor: "#805382",
      pointHoverRadius: 1,
      pointHoverBackgroundColor: "#805382",
      pointHoverBorderColor: "#805382",
      pointHoverBorderWidth: 7,
      pointRadius: 0,
      pointHitRadius: 30,
      data:testsuiteData.map(d=> {return d.testsuite})

      // [
      // testsuiteData[0].testsuite,
      // testsuiteData[1].testsuite,
      // testsuiteData[2].testsuite,
      // testsuiteData[3].testsuite,
      // testsuiteData[4].testsuite,
      // testsuiteData[5].testsuite],
    },
    {
      fill: false,
      label: "Test Cases",
      lineTension: 0.2,
      backgroundColor: "#E0A800",
      borderColor: "#E0A800",
      borderWidth: 1,
      pointBorderColor: "#E0A800",
      pointBackgroundColor: "#E0A800",
      pointHoverRadius: 1,
      pointHoverBackgroundColor: "#E0A800",
      pointHoverBorderColor: "#E0A800",
      pointHoverBorderWidth: 7,
      pointRadius: 0,
      pointHitRadius: 30,
      data:testsuiteData.map(d=> {return d.testcase})

      // data: [
      //   testsuiteData[0].testcase,
      //   testsuiteData[1].testcase,
      //   testsuiteData[2].testcase,
      //   testsuiteData[3].testcase,
      //   testsuiteData[4].testcase,
      //   testsuiteData[5].testcase
      // ],
    },
  ],
}} options={options} width={959.5} height={335} />
        </div>
        <div className="col">
          <UpcomingTest />
        </div>
      </div>
    </>
  );
}

export default Statistics;
