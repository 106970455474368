import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import { GoPlus } from "react-icons/go";
import "tippy.js/themes/light.css";
import "./CreateApplication.css";
import { API } from "./../../../../global";
import { useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";

let arrList = [];
let mailList = [];

const options = [
  {
    scroll: false,
    backdrop: false,
    placement: "end",
  },
];

function OffCanvasExample({ ...props }) {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow((s) => !s);
  const [showResults, setShowResults] = useState(false);
  const onClick = () => setShowResults((s) => !s);

  const handleClose = () => {
    setShow(false);
  };

  // const [tenant_id, setTenantId]=useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [project_id, setProject_id] = useState("");
  const [stakeholderlist, setStakeholderlist] = useState([]);
  const [checkTS, setCheckTS] = useState([]);
  // const [createdBy, setCreatedBy] = useState("");
  // const [updatedBy, setUpdatedBy] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [projectlist, setProjectlist] = useState([]);
  // const [createApp, setCreateApp] = useState([]);

  const NewApplication = {
    tenant_id: "12",
    name: name,
    description: description,
    type: type,
    project_id: project_id,
    stakeholder: mailList,
    created_by: localStorage.getItem("user-id"),
    updated_by: localStorage.getItem("user-id"),
  };
  const proId = localStorage.getItem("project-id");

  useEffect(() => {
    getProject();
    // getStakeholder();
    // eslint-disable-next-line
  }, [proId]);
  const validate = (values) => {
    let errors = {};

    if (!NewApplication.name) {
      errors.name = "Cannot be blank";
    }
    if (!NewApplication.description) {
      errors.description = "Cannot be blank";
    }
    if (!NewApplication.project_id) {
      errors.project_id = "Cannot be blank";
    }
    if (!NewApplication.type) {
      errors.type = "Cannot be blank";
    }
    if (!NewApplication.stakeholder) {
      errors.stakeholder = "Cannot be blank";
    }
    return errors;
  };

  const getProject = async () => {
    const appList = await fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    });

    const list = await appList.json();
    setProjectlist(
      list.result.data.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      )
    );
    proId !== null
      ? setProject_id(proId)
      : setProject_id(list.result.data[0]._id);
    proId !== null
      ? getStakeholder(proId)
      : getStakeholder(list.result.data[0]._id);
  };
  const getStakeholder = (id) => {
    // setLoading(true)
    fetch(`${API}/admin/stakeholder/get/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((pro) => {
        setStakeholderlist(pro.result.data);
      });
    // .then(()=> setLoading(false))
  };
  //   const checkList =(e)=>{
  //     let arr = [...checkTS];
  //     const value = e;
  //     const index = arr.findIndex((val) => val === value);
  //     if (index > -1) {
  //       arr = [...arr.slice(0, index)];
  //     } else {
  //       arr.push(value);
  //     }
  //     setCheckTS(arr);
  //     console.log(arr);
  //  }

  // let finalArr = [];

  const createApplication = () => {
    setBtnDisable(true);
    console.log(arrList);
    checkTS.map((ar) => mailList.push(ar._id));
    validate();
    if (Object.keys(validate()).length === 0) {
      fetch(`${API}/admin/application`, {
        method: "POST",
        body: JSON.stringify(NewApplication),
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("access-key")}`,
        },
      })
        .then(() => {
          handleClose();
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setFormErrors(validate());
      setBtnDisable(false);
    }
  };

  return (
    <div>
      <div
        className="create-app-btn"
        onClick={toggleShow}
        role="button"
        tabIndex="0"
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            toggleShow();
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <span>
          <GoPlus className="add-icon" />
        </span>
        Create Application
      </div>

      <Offcanvas
        className="create-app-canvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="create-app">
            <span>Create Application</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Form.Label className="create-app-label" htmlFor="basic-url">
          Project Name
        </Form.Label>
        <span>
          <Form.Select
            name="Select project"
            className="app-story-box"
            onChange={(event) => {
              setProject_id(event.target.value);
              getStakeholder(event.target.value);
              if (event.target.value.length !== 0) {
                setFormErrors((current) => {
                  const { project_id, ...rest } = current;
                  return rest;
                });
              } else if (event.target.value.length === 0) {
                setFormErrors((current) => {
                  const changes = { ...current, project_id: "Cannot be blank" };
                  return changes;
                });
              }
            }}
          >
            <option value="" selected disabled>
              Select Project
            </option>
            {projectlist.map((data) => {
              return (
                <option
                  // selected={data._id}
                  value={data._id}
                  className="opt"
                >
                  {data.name}
                </option>
              );
            })}
          </Form.Select>
          {formErrors.project_id && (
            <span className="error">{formErrors.project_id}</span>
          )}
        </span>

        <br />
        <Form.Label className="create-app-label" htmlFor="basic-url">
          Application Name
        </Form.Label>
        <span>
          <Form.Control
            type="text"
            placeholder="Enter application name"
            className="app-story-box"
            onChange={(event) => {
              setName(event.target.value);
              if (event.target.value.length !== 0) {
                setFormErrors((current) => {
                  const { name, ...rest } = current;
                  return rest;
                });
              } else if (event.target.value.length === 0) {
                setFormErrors((current) => {
                  const changes = { ...current, name: "Cannot be blank" };
                  return changes;
                });
              }
            }}
          />
          {formErrors.name && <span className="error">{formErrors.name}</span>}
        </span>

        <br />
        <Form.Label className="create-app-label" htmlFor="basic-url">
          Application Description
        </Form.Label>
        <span>
          <Form.Control
            as="textarea"
            type="text"
            placeholder="Enter an Application description"
            className="app-desc-box"
            onChange={(event) => {
              setDescription(event.target.value);
              if (event.target.value.length !== 0) {
                setFormErrors((current) => {
                  const { description, ...rest } = current;
                  return rest;
                });
              } else if (event.target.value.length === 0) {
                setFormErrors((current) => {
                  const changes = {
                    ...current,
                    description: "Cannot be blank",
                  };
                  return changes;
                });
              }
            }}
          />
          {formErrors.description && (
            <span className="error">{formErrors.description}</span>
          )}
        </span>
        <br />
        <Form.Label className="create-app-label" htmlFor="basic-url">
          Select Application Type
        </Form.Label>
        <span>
          <Form.Select
            name="Select Application Type"
            className="app-story-box"
            onChange={(event) => {
              setType(event.target.value);
              if (event.target.value.length !== 0) {
                setFormErrors((current) => {
                  const { type, ...rest } = current;
                  return rest;
                });
              } else if (event.target.value.length === 0) {
                setFormErrors((current) => {
                  const changes = { ...current, type: "Cannot be blanck" };
                  return changes;
                });
              }
            }}
          >
            <option value="">Select Application Type</option>
            <option value="Web" className="opt">
              Web
            </option>
            <option value="Desktop" className="opt">
              Desktop
            </option>
            <option value="Mobile" className="opt">
              Mobile
            </option>
          </Form.Select>
          {formErrors.type && <span className="error">{formErrors.type}</span>}
        </span>
        <br />
        <Form.Label className="create-app-label" htmlFor="basic-url">
          Select Stakeholder
        </Form.Label>

        <Multiselect
          className="CAPP-stake-select"
          displayValue="mail"
          // selectedValues={finalArr}
          // onKeyPressFn={function noRefCheck() { }}
          // onRemove={function noRefCheck(e) { arrList = e }}

          // // onSearch={function noRefCheck() { }}
          onSelect={function noRefCheck(e) {
            setCheckTS(e);
            arrList = e;
            if (e.length !== 0) {
              setFormErrors((current) => {
                const { stakeholder, ...rest } = current;
                return rest;
              });
            }
            // setSelectedList([...e, ...selectedList])
          }}
          onRemove={function noRefCheck(e) {
            if (e.length === 0) {
              setFormErrors((current) => {
                const changes = { ...current, stakeholder: "Cannot be blank" };
                return changes;
              });
            }
          }}
          options={[...stakeholderlist]}
          showCheckbox
        />
        {formErrors.stakeholder && (
          <span className="error">{formErrors.stakeholder}</span>
        )}
        {/* * <span>
          <Form.Control
            type="text"
            placeholder="Enter an project name"
            className="app-story-box"

          />
        </span> */}

        <br />
        <div>
          <input
            type="checkbox"
            onClick={onClick}
            style={{ margin: "5px", marginLeft: "20px", zoom: "1" }}
          />
          <span style={{ fontSize: "12px", margin: "5px" }}>
            Add JIRA Project
          </span>
        </div>
        <br />
        {showResults ? (
          <div className="">
            <Form.Label className="create-app-label" htmlFor="basic-url">
              JIRA URL
            </Form.Label>
            <span>
              <Form.Control
                type="text"
                className="app-story-box"
                placeholder="Enter JIRA url"
              />
            </span>
            <br /> <br />
            <Form.Label className="create-app-label" htmlFor="basic-url">
              JIRA Client ID
            </Form.Label>
            <span>
              <Form.Control
                type="text"
                placeholder="Enter JIRA client ID"
                className="app-story-box"
              />
            </span>
            <br /> <br />
            <Form.Label className="create-app-label" htmlFor="basic-url">
              JIRA Secret Key
            </Form.Label>
            <span>
              <Form.Control
                type="password"
                placeholder="Enter JIRA secret key"
                className="app-story-box"
              />
            </span>
          </div>
        ) : null}
        {/* <br/> */}
        <span className="app-button">
          <button
            type="button"
            className="create-app-button"
            disabled={btnDisable}
            onClick={() => createApplication()}
          >
            Create
          </button>
          <button
            type="button"
            disabled={btnDisable}
            className="cancel-app-button"
            onClick={() => {
              handleClose();
              setName("");
              setDescription("");
              setType("Web");
              setProject_id(proId);
              setFormErrors({});
            }}
          >
            Cancel
          </button>
        </span>
      </Offcanvas>
    </div>
  );
}

export default function CreateApplication() {
  return (
    <>
      {options.map((props, idx) => (
        <OffCanvasExample key={idx} {...props} placement={props.placement} />
      ))}
    </>
  );
}
