import React from "react";
import Table from "react-bootstrap/Table";
import "./ReportTable.css";
// import { API } from "./../../../../global";
// import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { Col, Container, Row } from "react-bootstrap";
// import Sidebar from "../../Layout/Sidebar/Sidebar";
import { API } from "../../../../global";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import Header from "../../../Layout/Navbar/Navbar";
import { useEffect } from "react";
import moment from "moment";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

// import { Oval } from "react-loader-spinner";

function Report() {
  const navigate = useNavigate();

  const { proId } = useParams();
  const [applist, setApplist] = useState([]);
  const [runLogList, setRunLogList] = useState([]);
  const [app_selected, setApp_selected] = useState("");
  const [loading, setLoading] = useState(false);

  // const [all_cases, setAll_cases] = useState([]);
  // const [run_log_status, setRunLog] = useState([]);
  const current_suite_name = localStorage.getItem("current_suite_name");

  const [testsuite_selected, setTestsuite_selected] = useState(current_suite_name);
  const [testsuitelist, setTestsuitelist] = useState([]);
  const [dropSearchlist, setDropSearchlist] = useState([]);
  const [searchApp, setSearchApp] = useState("");
  const [dropSearchlistCase, setDropSearchlistCase] = useState([]);
  const [searchCase, setSearchCase] = useState("");
  const [sort, setSort] = useState("asc");
  const [expand, setExpand] = useState(false);

  const project_id = localStorage.getItem("project-id")
  const app_id = localStorage.getItem('app_id')
  const app_name = localStorage.getItem("app-id");
  const current_testsuite = localStorage.getItem("current_testsuite");

  const get_project_id = () => {
    if (localStorage.getItem("project-id")) {
      navigate(`/qe/testsuite/report/table/${project_id}`);
    }
  };

  useEffect(() => {
    get_project_id();
    if(app_id){
        getApplication();
        getTestsuitesByAppId(app_id);
    }else{
        getApplication();
        getTestsuitesByProjectId();
    }
     getRunLog(current_testsuite);
    // eslint-disable-next-line
  }, [proId]);


  const getApplication = () => {
    setApplist([]);
    fetch(`${API}/admin/application/${project_id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {
        app.result.data.length > 0 ? setApplist(app.result.data.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : setApplist([]);
        app.result.data.length > 0 ? setDropSearchlist(app.result.data) : setDropSearchlist([]);
      })
  
  };

  const getTestsuitesByProjectId = () => {
    setApp_selected("");
    fetch(
      `${API}/tests/testsuite/application/?project_id=${project_id}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    ) //promise
      .then((data) => data.json()) //Response Object
      .then((ts) => { console.log(ts);
        setTestsuitelist(ts.result.data.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        setDropSearchlistCase(ts.result.data)

      })
  };
  const sorting = (order) => {
    if (sort === "asc") {
      const sorted = [...runLogList].sort((a, b) => 
        a[order] > b[order] ? 1 : -1
      );
      setRunLogList(sorted);
      setSort("dsc");
    }
    if (sort === "dsc") {
      const sorted = [...runLogList].sort((a, b) => 
        a[order] < b[order] ? 1 : -1
      );
      setRunLogList(sorted);
      setSort("asc");
    }
  }

  const getTestsuitesByAppId = (appid, name) => {
    if(name==='drop'){
      localStorage.removeItem('current_suite_name')
      localStorage.removeItem('current_testsuite')
      setTestsuite_selected("")
    }
    setRunLogList([])
    setTestsuitelist([])
    fetch(
      `${API}/tests/testsuite/application/?project_id=${project_id}&application_id=${appid}`,
      {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }
    ) //promise
      .then((data) => data.json()) //Response Object
      .then((ts) => {
        setTestsuitelist(ts.result.data);
        setDropSearchlistCase(ts.result.data)
      })
  };

  const handleDropChangeApp =(e)=> {
    setSearchApp(e);
    console.log(e);
    if(e.length === 0 ){
      setApplist(dropSearchlist)
    }else{
      let fillteredApp= dropSearchlist.filter(d=>d.name.toLowerCase().includes(e.toLowerCase()));
      setApplist(fillteredApp)
    } 
  }
  const handleDropChangeCase =(e)=> {
    setSearchCase(e);
    console.log(e);
    if(e.length === 0 ){
      setTestsuitelist(dropSearchlistCase)
    }else{
      let fillteredApp= dropSearchlistCase.filter(d=>d.name.toLowerCase().includes(e.toLowerCase()));
      setTestsuitelist(fillteredApp)
    } 
  }
  const getRunLog = (id) => {
    if(id){
      localStorage.setItem('current_testsuite', id)
      setLoading(true);
      fetch(`${API}/tests/testsuites/report/runlog/${id}`, {
        method: "GET",
        headers: {
          authorization: `${localStorage.getItem("access-key")}`,
        },
      })
        .then((value) => value.json())
        .then((pro) => { 
          let dataArr = [...pro.result.data];
          dataArr = dataArr.map(a=>({
            environment:a.test_suite_id.environment,
            name:a.updated_by.Ad_username,
            run_status:a.test_suite_id.last_run_status,
            ...a
          }))
          setRunLogList(dataArr);
          setLoading(false);
  
        });
    }
  
  };

  return (
    <div className=" overflow-hidden">
      <Header />
     
     { loading ? 
     <div style={{ marginTop: "20%", marginLeft: "5%" }}>
          <Oval
            height={80}
            width={2000}
            color="#805382"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#F0E3F1#AB78AE"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>:
        <>
      <div className="reportList_testsuite">
      <Container fluid>
        <Row className="report_test-row">
          <Col>
            <div className="d-flex first-head justify-content-start">
              Test Suite Execution Report List
            </div>
          </Col>

          <Col md={7}>
            <div className="d-flex justify-content-end report-icons">
              <div className="btn-group">
                <label className="TC-App position-absolute">Application</label>
                <button
                  type="button"
                  className="TC-bulk-btn-TCR me-3 dropdown-toggle"
                  data-bs-toggle="dropdown"
                  size="sm"
                  aria-expanded="false"
                >
              {(app_selected && app_selected.length > 10) ? `${app_selected.slice(0, 10)}...` :(app_selected && app_selected.length <= 10) ? app_selected: (app_name && app_name.length > 10)  ? `${app_name.slice(0, 10)}...`: (app_name && app_name.length <= 10) ? app_name : "- - Select - -"}
                </button>
                <ul className="dropdown-menu dScroll">
                {dropSearchlist.length !== 0 && <li><input type="text" value={searchApp} onChange={(e)=>handleDropChangeApp(e.target.value)}/></li>}
                   {applist.length === 0 ? (
                    <li className="dropdown-item dScroll">No Application found</li>
                     ): (
                  applist.map((app, i) => {
                      return (
                        <li
  key={i}
  tabIndex={0}
  className="dropdown-item"
  onClick={() => {
    getTestsuitesByAppId(app._id, 'drop');
    setApp_selected(app.name);
    localStorage.setItem("app-id", app.name);
    localStorage.setItem("app_id", app._id);
  }}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault(); // Prevent default action (like scrolling)
      e.target.click(); // Programmatically trigger the click event
    }
  }}
>
  {app.name}
</li>

                      )
                  })
  )}
                </ul>
              </div>

              <div className="btn-group">
                <label className="TC-suite position-absolute">Test Suite</label>
                <button
                  type="button"
                  className="TC-bulk-btn-TSR me-3 dropdown-toggle"
                  data-bs-toggle="dropdown"
                  size="sm"
                  aria-expanded="false"
                >
                  {(testsuite_selected && testsuite_selected.length > 10) ? (`${testsuite_selected.slice(0, 10)}...`) : (testsuite_selected&&testsuite_selected.length <= 10)? testsuite_selected : "- - Select - -"}
                </button>
                <ul className="dropdown-menu dScroll">
                {dropSearchlistCase.length !== 0 && <li><input type="text" value={searchCase} onChange={(e)=>handleDropChangeCase(e.target.value)}/></li>}

                  {testsuitelist.length === 0 ? "No Testsuite Found"
                    : testsuitelist.map((test, i) => {
                      return (
                        <li
  key={i}
  tabIndex={0}
  className="dropdown-item"
  onClick={() => {
    getRunLog(test._id);
    setTestsuite_selected(test.name);
    localStorage.setItem('current_suite_name', test.name);
  }}
  onKeyDown={(e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault(); // Prevent the default action
      e.target.click(); // Simulate a click event
    }
  }}
>
  {test.name}
</li>

                      )
                    })}

                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      </div>  
      {runLogList.length > 0 ? (
        <Table className="reportList-table">
          <thead className='zIndex'
            style={{
              backgroundColor: "#E6C6E840",
              color: "#805382",
              zIndex:-1
              // position:"relative",
            }}
          >
            <tr>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Execution Time
                <span onClick={() =>{
                        sorting('updated_at'); setExpand(!expand)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('updated_at');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span>
              </th>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Environment
                <span onClick={() =>{
                        sorting('environment');setExpand(!expand)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('environment');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span>
              </th>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Executed By
                <span onClick={() =>{
                        sorting('name'); setExpand(!expand)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('name');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span>
              </th>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Status
                <span onClick={() =>{
                        sorting('run_status'); setExpand(!expand)
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                              e.preventDefault();
                              sorting('run_status');
                              setExpand(!expand);
                          }
                      }}
                        >
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                      </span>
              </th>
              <th style={{ borderRight: "1px solid #805382", width: "16%" }}>
                Instant/Scheduled
                {/* <span onClick={() =>{
                        sorting('createdAt')|| setExpand(!expand)
                        }}>
                        {!expand ? <MdArrowDropDown size={18} /> : <MdArrowDropUp size={18} />}
                  </span> */}
              </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center" }}>
            {runLogList.map((data, i) => (
              <tr style={{ height: "10px" }}>
                <td>{moment(data.updated_at).format("D MMM h:mm a")}</td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/qe/testReport/${data._id}`)}
                >
                  {data.environment}
                </td>
                <td>{data.name}</td>
                <td
                  style={{
                    color:
                      data.run_status === "failed"
                        ? "#DC3545"
                        : "#7EB965",
                    fontWeight: "bold",
                  }}
                >
                  {data.run_status}
                </td>
                <td>Instant</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) :  (app_name === null && app_selected === '') ? (
        <label style={{ marginTop: "20%",marginLeft:"45%" }}>Please Select an Application</label>
      ) : (testsuite_selected === null || testsuite_selected === '') ? 
      <label style={{ marginTop: "20%",marginLeft:"45%" }}>Please Select a Test suite</label>
       :""
      }
      </>}
   
    </div>
  );
}

export default Report;
