import React, { useEffect, useState } from "react";
import Header from "../../Layout/Navbar/Navbar";
import "./EditTestcase.css";
// import { GoPlus } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { SelectionText } from "@uiw/react-textarea-code-editor";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Editor from "@monaco-editor/react";
import { API } from "../../../global";
import publish from "./../../../assets/Icons/published.svg";
import { Col, Container, Row} from "react-bootstrap";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
// import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
// import execute from './../../../assets/Icons/run-icon.svg';
import "prismjs/components/prism-gherkin";
import "prismjs/themes/prism.css";

export default function EditTestcase() {
  const { id } = useParams();

  const [testcase, setTestcase] = useState(null);

  const [testSteps, setTestSteps] = useState("");

  const [loading, setLoading] = useState(true);

  const [testcase_id, setTestcase_id] = useState("");

  useEffect(() => {
    fetch(`${API}/tests/testcases/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }) //promise
      .then((value) => value.json()) //Response Object
      .then((tcs) => {
        console.log(tcs.result.data, "data")
        setTestcase(tcs.result.data);
        tcs.result.data.test_steps !== undefined && setTestSteps(tcs.result.data.test_steps);
        setTestcase_id(tcs.result.data.test_case_id);
        setLoading(false);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [id]);

  let edit_test;

  if (loading) {
    edit_test = (
      <div>
        <Header />
        <div className="edit-loader">
          <Oval
            height={80}
            width={1800}
            color="#805382"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#F0E3F1#AB78AE"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      {testcase ? (
        <Update testcase={testcase} teststeps={testSteps} val={testcase_id} id={id} />
      ) : (
        <div>

          {edit_test}
        </div>
      )}
    </div>
  );
}

function Update({ testcase, teststeps, val, id }) {
  const ReviseTestcase = () => {
    fetch(`${API}/tests/testcases/revision/${id}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      .then((revise) => {
        setRevision(revise.result.data);
        setMake(revise.result.data[revise.result.data.length - 1].test_steps);
        setRevise_name(
          revise.result.data[revise.result.data.length - 1].updated_by.Ad_username
        );
        setRevise_date(revise.result.data[revise.result.data.length - 1].updatedAt);
      });
  };
  const navigate = useNavigate();
  const textRef = React.useRef();
  const [revision, setRevision] = useState([]);
  const [make, setMake] = useState("");
  const [userstory, setUserstory] = useState(testcase.user_story);
  const [name, setName] = useState(testcase.test_case_name);
  const [type, setType] = useState(testcase.test_case_type);
  const [projectlist, setProjectlist] = useState([]);
  const [applist, setApplist] = useState([]);
  const [projectId, setProjectId] = useState(testcase.project_id);
  const [applicationId, setApplicationId] = useState(testcase.application_id);
  const [formErrors, setFormErrors] = useState({});
  const [description, setDescription] = useState(
    testcase.test_case_description
  );
  const [eresult, setEresult] = useState(testcase.expected_result);
  const [code, setCode] = useState(testcase.test_steps ? testcase.test_steps : '' );
  const [tick, setTick] = useState("");
  const [tickMark, setTickMark] = useState(true);
  const [revise_name, setRevise_name] = useState("");
  const [revise_date, setRevise_date] = useState(make.updatedAt);
  const username = testcase.created_by.Ad_username;
  const updated_user =
    testcase.updated_by === undefined
      ? testcase.created_by.Ad_username
      : testcase.updated_by.Ad_username;
  const handleTestcaseType = (event) => {
    setType(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {test_case_type, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,test_case_type:"Cannot be blank"}
        return changes
       })
     }  
  };

  const UpdatedTestcase = {
    updated_by: localStorage.getItem("user-id"),
    test_case_name: name,
    test_case_type: type,
    user_story: userstory,
    test_case_description: description,
    last_run_status:"Pending",
    expected_result: eresult,
    project_id: projectId,
    published: true,
    application_id: applicationId,
    test_steps: code,
  };
  const validate = () => {
    let errors = {};
    if (!UpdatedTestcase.user_story) {
      errors.user_story = "Cannot be blank";
    } 
    if (!UpdatedTestcase.test_case_type) {
      errors.test_case_type = "Cannot be blank";
    } 
    if (!UpdatedTestcase.test_case_name) {
      errors.test_case_name = "Cannot be blank";
    } 
    if (!UpdatedTestcase.test_case_description) {
      errors.test_case_description = "Cannot be blank";
    } 
    if (!UpdatedTestcase.expected_result) {
      errors.expected_result = "Cannot be blank";
    } 
    if (!UpdatedTestcase.test_steps) {
      errors.test_steps = "Cannot be blank";
    } 
    if (!UpdatedTestcase.project_id) {
      errors.project_id = "Cannot be blank";
    } 
    if (!UpdatedTestcase.application_id) {
      errors.application_id = "Cannot be blank";
    } 
 
    return errors;
  };

  // const testcase_Id = testcase.test_case_id;
  const revised = {
    // tenant_id: "",
    testcase_id: id,
    test_steps: code,
    updated_by: localStorage.getItem("user-id"),
  };
  useEffect(() => {
    ReviseTestcase();
    if (textRef.current) {
      const obj = new SelectionText(textRef.current);
      console.log("obj:", obj);
    }
    getProjects();
    getApplication(testcase.project_id);
    // eslint-disable-next-line
  }, []);
  const handleProjectId = (event) => {
    setProjectId(event.target.value);
    getProjects();
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {project_id, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,project_id:"Cannot be blank"}
        return changes
       })
     } 
  };
  const handleApplicationId = (event) => {
    setApplicationId(event.target.value);
    if(event.target.value.length !== 0) {
      setFormErrors(current => {
    const {project_id, ...rest} = current;
    return rest
       });
     }else if(event.target.value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,application_id:"Cannot be blank"}
        return changes
       })
     } 
  };
  const getProjects = async () => {
    const userId= localStorage.getItem('user-id')

    await fetch(`${API}/admin/project`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((value) => value.json())
      //.then((data) => console.log(data.data))
      .then((pro) => {

      let projectData = [...pro.result.data];
        console.log(projectData,'project');
        let not_empty=[];
        let userList = [];
        let assign_project_list = []
        projectData.forEach((d,i)=>{
        if(d.project_users.length !==0){
          not_empty.push({i:i, ...d})
         }
        })
        not_empty.forEach((a)=>{
           userList.push(a.project_users)
       
        })
         const assign_project = userList.flat().filter(d=>d.user_id===userId);
        projectData.forEach((d)=>{
         if(assign_project.some(a=>a.project_id===d._id)){
           assign_project_list.push(d)
         }
        })
        // console.log(pro.data);
        setProjectlist(assign_project_list.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        // pro.data.length > 0 ? setProjectId(pro.data[0]._id) : setProjectId();
      });
    // .then(() => getApplication());
    //setLoading(false);
  };
  const getApplication = async (val) => {
    setApplist([]);
    await fetch(`${API}/admin/application/${val}`, {
      method: "GET",
      headers: {
        authorization: `${localStorage.getItem("access-key")}`,
      },
    })
      .then((val) => val.json())
      .then((app) => {
        console.log(app.result.data, "application")
        app.result.data.length > 0 ? setApplist(app.result.data.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) : setApplist([]);
      });

  };

  const reviseTestcase = () => {
    fetch(`${API}/tests/testcases/revision`, {
      method: "POST",
      body: JSON.stringify(revised),
      headers: {
        "content-type": "application/json",
        authorization: `${localStorage.getItem("access-key")}`,
      },
    }).then(() => navigate("/qe/testcase"));
  };

  const editTestcase = (a) => {
    validate()
    console.log("up", UpdatedTestcase);
    if(Object.keys(validate()).length === 0) {
    if (code === teststeps) {
      let publish_testcase =
        a === "0"
          ? { ...UpdatedTestcase,published: false }
          : { ...UpdatedTestcase, published: true };
          console.log(publish_testcase, '0');

      fetch(`${API}/tests/testcases/update/${testcase._id}`, {
        method: "PUT",
        body: JSON.stringify(publish_testcase),
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("access-key")}`,
        },
      }).then(() => {
        // navigate("/qe/testcase");
        a === "0"
          ? toast.success("Saved as Draft Successfully")
          : toast.success("Published Successfully");
      });
    } else {
      let publish_testcase =
        a === "1"
          ? { ...UpdatedTestcase,published: true }
          : { ...UpdatedTestcase, published: false };
          console.log(publish_testcase, '1');
      fetch(`${API}/tests/testcases/update/${testcase._id}`, {
        method: "PUT",
        body: JSON.stringify(publish_testcase),
        headers: {
          "content-type": "application/json",
          authorization: `${localStorage.getItem("access-key")}`,
        },
      })
        .then(() => reviseTestcase())
        .then(() => {
          // navigate("/qe/testcase");
          a === "0"
          ? toast.success("Saved as Draft Successfully")
          : toast.success("Published Successfully");
        });
    }
  }else{
    setFormErrors(validate());
  }
  };
  // function handleEditorChange(value, event) {
  //   setCode(value);
  // }
  return (
    <>
      <div className="overflow-hidden">
        <Header />
        <div>
          <Container fluid className="edit-TC position-fixed">
            <Row className="test-row">
            <Col sm={5}>
                <div className="d-flex first-head justify-content-start">
                  <span className="mt-1" style={{ color: "#7eb965" }}>Test Cases</span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <Container fluid className="edit-TC-editor">
            <Row>
              <Col sm={3}>
                <div className="sec-head d-flex justify-content-start">
                  Edit Test Case
                </div>
              </Col>
              {/*Adding 1st heading update options */}

              <Col sm={8}>
                <div className="TC-edit-updates">
                  <div className="edit-create-up">
                    <div>Created By</div>
                    <div className="updates">
                      <strong>{username}</strong>
                      <br />
                      on {moment(testcase.createdAt).format("D MMM h:mm a")}
                    </div>
                  </div>

                  <div className="edit-up">
                    <div>Last Updated By</div>
                    <div className="updates">
                      <strong>{updated_user}</strong>
                      <br />
                      on {moment(testcase.updatedAt).format("D MMM h:mm a")}
                    </div>
                  </div>

                  <div className="last-up">
                    <div>Last Run Time</div>
                    <div className="updates">
                      {testcase.last_run_status ==='Pending' ? '-----' : moment(testcase.last_run).format("D MMM h:mm a")}
                    </div>
                  </div>
                  <div className="status-up">
                    <div>Last Run Status</div>
                    <div className="updates"
                      style={{
                        color:
                          testcase.last_run_status === "passed"
                            ? "#7EB965"
                            : testcase.last_run_status === "failed"
                              ? "#DC3545"
                              : "black",
                        fontWeight: "bold",
                      }}
                    >
                      {testcase.last_run_status === undefined
                        ? "Not Executed"
                        : testcase.last_run_status}
                    </div>
                  </div>
                  {/* <div className=""><button className="clt-run-button">
              <img
                src={execute}
                style={{ color: "white", backgroundColor: "none",width:"19px" }}
                alt="run icon"
                className="me-2"
              />
           
            Run</button>
            </div> */}
                </div>
          
              </Col>
              {/*Ending 1st heading update options */}
             

            </Row>
          </Container>
        </div>
        <Container fluid className="mt-4">
          <Row>
            <Col sm={4}>
              <div className="justify-content-center ms-5 text-start">
                <Form className="TC-edt-user-story-menus">
                  <Form.Group>
                    <Form.Label className="mb-1">Select Project</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Select Project"
                      className="edt-form mb-3"
                      required
                      value={projectId}
                      onChange={handleProjectId}
                    >
                      {projectlist.map((data, i) => (
                        <option
                          selected={data._id === projectId ? true : false}
                          value={data._id}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                    {formErrors.project_id && (
            <span className="error">{formErrors.project_id}</span>
          )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mb-1">Select Application</Form.Label>
                    <Form.Select
                      type="text"
                      placeholder="Select Application"
                      className="edt-form mb-3"
                      required
                      value={applicationId}
                      onChange={handleApplicationId}
                    >
                      {applist.map((data, i) => (
                        <option
                          key={i}
                          selected={data._id === applicationId ? true : false}
                          value={data._id}
                        >
                          {data.name}
                        </option>
                      ))}
                    </Form.Select>
                    {formErrors.application_id && (
            <span className="error">{formErrors.application_id}</span>
          )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mb-1">User Story</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Link User Story"
                      className="edt-form mb-3"
                      value={userstory}
                      onChange={(event) => {
                        setUserstory(event.target.value)
                      if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {user_story, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,user_story:"Cannot be blank"}
                return changes
               })
             }   
                      }}
                    />
                    {formErrors.user_story && (
            <span className="error">{formErrors.user_story}</span>
          )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mb-1">Test Case Type</Form.Label>
                    <Form.Select
                      type="text"
                      className="edt-form mb-3"
                      required
                      value={type}
                      onChange={handleTestcaseType}
                    >
                      <option value="Functional">Functional</option>
                      <option value="System">System</option>
                      <option value="Performance">Performance</option>
                      <option value="UI">UI</option>
                      <option value="Backend">Backend</option>
                    </Form.Select>
                    {formErrors.test_case_type && (
            <span className="error">{formErrors.test_case_type}</span>
          )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mb-1">Test Case Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      className="edt-form mb-3"
                      value={name}
                      onChange={(event) =>{
                        setName(event.target.value)
                        if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {test_case_name, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,test_case_name:"Cannot be blank"}
                return changes
               })
             }   
                      }}
                    />
                    {formErrors.test_case_name && (
            <span className="error">{formErrors.test_case_name}</span>
          )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mb-1">
                      Test Case Description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      className="edt-form mb-3"
                      style={{
                        border: "1px #9F9F9F solid",
                        borderRadius: "5px",
                        height: "38px",
                      }}
                      value={description}
                      onChange={(event) =>  {
                      setDescription(event.target.value)
                        if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {test_case_description, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,test_case_description:"Cannot be blank"}
                return changes
               })
             } 
                    }}
                    />
                    {formErrors.test_case_description && (
            <span className="error">{formErrors.test_case_description}</span>
          )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mb-1">Expected Result</Form.Label>
                    <Form.Control
                      as="textarea"
                      required
                      className="edt-form mb-3"
                      value={eresult}
                      onChange={(event) => {
                      setEresult(event.target.value)
                        if(event.target.value.length !== 0) {
              setFormErrors(current => {
            const {expected_result, ...rest} = current;
            return rest
               });
             }else if(event.target.value.length === 0){
              setFormErrors(current => {
                const changes =  {...current,expected_result:"Cannot be blank"}
                return changes
               })
             } 
                    }}
                    />
                    {formErrors.expected_result && (
            <span className="error">{formErrors.expected_result}</span>
          )}
                  </Form.Group>
                </Form>
              </div>
            </Col>
            <Col sm={8}>
              {/*Adding 1st heading update options */}
              <Col className="text-start edt-editor-head mb-1">Editor</Col>
              <div className="edit-clone">
                <div className="editor-code d-flex justify-content-end">
                  <Editor
                    className="crt-react-editors"
                    value={code}
                    // defaultValue="WRITE YOUR TEST CASE"
                    language="javascript"
                    onChange={value =>{
                      setCode(value)
                      if(value.length !== 0) {
      setFormErrors(current => {
    const {test_steps, ...rest} = current;
    return rest
       });
     }else if(value.length === 0){
      setFormErrors(current => {
        const changes =  {...current,test_steps:"Cannot be blank"}
        return changes
       })
     } 
                    } }
                    highlight={code => highlight(code, languages.gherkin).split('\n')
                    .map(
                      (line, index) => `<span><span style=' color:grey'>${index + 1}</span><span>${line}</span></span>`
                    )
                    .join('\n')}
                    style={{
                      overflow: "auto",
                      maxHeight: 500,
                    }}
                    padding={1}
                   
                    WRITE YOUR TESECASE HERE
                  />
                   {formErrors.test_steps && (
            <span className="ET-error position-absolute bottom-0 end-3">{formErrors.test_steps}</span>
          )}
                </div></div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="mt-4">
          <Row>
            <Col>
            <div className="Editor-bottom-btns d-flex justify-content-end me-3 mb-3">
                <button
                  // variant="normal"
                  // size="sm"
                  className="me-3 edit-save-as"
                  onClick={() => editTestcase("0")}
                >
                  Save As Draft
                </button>
                <button
                  // variant="normal"
                  // size="sm"
                  type="submit"
                  className="me-3 edit-publish"
                  onClick={() => editTestcase("1")}
                >
                  Publish
                </button>
                <button
                  // variant="normal"
                  // size="sm"
                  type="cancel"
                  className="edit-cancel"
                  onClick={() => navigate("/qe/testcase")}
                >
                  Cancel
                </button>
              </div>

            </Col>
          </Row>
        </Container>
        {/* Here adding second editor */}
        {revision.length > 0 && <>
          <Container fluid className="mt-4">
            <Row>
              <Col sm={4}>
                <div className="ms-5 text-start">
                  <span className="sec-head-para">Test Case Revision</span>

                  <div className="ts-revision me-4">
                    <Table className="revise-tbl">
                      <thead>
                        <tr>
                          <th className="text-start align-middle">Revision</th>
                        </tr>
                      </thead>
                      <tbody>
                        {revision
                          .slice(0)
                          .reverse()
                          .map((revise, i) => {
                            return (
                              <tr style={{ cursor: "pointer" }}>
                                <td
                                  className="text-start"
                                  key={i}
                                  onClick={() => {
                                    setTick(revise._id);
                                    setTickMark(false);
                                    setMake(revise.test_steps);
                                    setRevise_name(revise.updated_by.Ad_username);
                                    setRevise_date(revise.updatedAt);
                                  }}
                                >
                                  {moment(revise.updatedAt).format(
                                    "D MMM h:mm a"
                                  ) +
                                    " " +
                                    "by" +
                                    " " +
                                    revise.updated_by.Ad_username}
                                  <span className="revision-icon">
                                    {revise._id === tick ? (
                                      <img src={publish} alt="" />
                                    ) : (
                                      ""
                                    )}
                                    {i === 0 && tickMark ? (
                                      <img className="" src={publish} alt="" />
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
              <Col sm={8}>
                <Col className="text-start sec-editor-head">
                  <div>Revised By</div>
                  <div className="updates">
                    <strong>{revise_name} </strong> on{" "}
                    {moment(revise_date).format("D MMM h:mm a")}
                  </div>
                </Col>
                <div className="edit-clone">
                  <div className="editor-code d-flex justify-content-end">
                    <Editor
                      width="calc(105% - 70px)"
                      language="javascript"
                      value={make}
                      className="edt-react-editors me-3 mt-3"
                      onValueChange={value => setCode(value)}
                      highlight={code => highlight(code, languages.gherkin)}
                      padding={10}
                      readOnly

                    // className="edt-react-editors p-2 me-3 mt-3"
                    // value={make}
                    // options={{ readOnly: true }}
                    // defaultLanguage="gherkin"
                    // variant="transparent"
                    />
                  </div></div>
              </Col>
            </Row>
          </Container>
          <Container fluid className="mt-4">
            <Row>
              <Col>
              <div className="ET-Editor-bottom-btns d-flex justify-content-end me-3 mb-3">
                <button
                  onClick={() => setCode(make)}
                  // variant="normal"
                  // size="sm"
                  className="mark-draft-btn mt-3"
                >
                  Mark This As Current
                </button>
              </div>
              </Col>
            </Row>
          </Container>
        </>}

        {/*Here the second editor is ending */}
      </div>
    </>
  );
}