import React,{useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from "react-toastify";
import { API } from '../../global';
import PasswordChecklist from "react-password-checklist";
import { useNavigate, useParams } from 'react-router';
import heading from '../../assets/img/heading.png';
import warningIcon from "../../assets/Icons/Warning.svg";
import { BsEye, BsEyeSlash } from "react-icons/bs";

import { TailSpin } from "react-loader-spinner";



const FreemiumReset = () => {
   const{ auth } = useParams();
   const navigate = useNavigate();
   const [showPass, setShowPass] = useState(false);
   const [visiblePassword, setVisiblePassword] = useState(false);
   const [visibleRePassword, setVisibleRePassword] = useState(false);
   const [showError, setShowError] = useState(false);
   const [showLoader, setShowLoader] = useState(false);
   const [showLoading, setShowLoading] = useState(false);
   const [showValidation, setShowValidation] = useState(false);
  const [subHeading, setSubHeading] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [cnfmPassword, setCnfmPassword] = useState('')

  useEffect(()=>{
    verifyAuth();
    // eslint-disable-next-line
  },[])
  const SuccessIcon = () => {
    return (
      <svg
        width="100"
        height="100"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          fill="none"
          stroke="#68E534"
          stroke-width="5"
          cx="100"
          cy="100"
          r="90"
          strokeLinecap="round"
          transform="rotate(-90 100 100)"
          className="circle"
        />
        <polyline
          fill="none"
          stroke="#68E534"
          points="44,114 86.5,142 152,69"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="tick"
        />
      </svg>
    )
  }
  const verifyAuth =()=> {
    setShowLoader(true)
    fetch(`${API}/verify/auth/users`, {
      method: "POST",
      body: JSON.stringify({
        auth_verification_key:auth,
      }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((a) => a.json())
      .then((res)=>{
       if(res.success){
        localStorage.setItem("access-key", res.result.token);
        localStorage.setItem("role-name", res.result.data.role_name[1]);
        setSubHeading(
          `Your email verification is confirmed. To proceed, kindly set your password.`
        );
       }else{
        setShowError(true)
        setSubHeading(
          `The verification link you provided seems to be incorrect.`
        );
       }
        setShowLoader(false)
      })
  }
// /verify/auth/users
     const submitPassword =(e)=> {
      setShowLoading(true)
      e.preventDefault();
      if(newPassword===cnfmPassword){
        fetch(`${API}/users/register/reset/${auth}`, {
          method: "PUT",
          body: JSON.stringify({
           password:newPassword,
           verify:true
          }),
          headers: {
            "content-type": "application/json",
          },
        })
          .then((a) => a.json())
          .then( async(b)=>{
            if(b.info.msg==="User successfully logged in"){
              localStorage.setItem('type',b.result.data.auth_type )
          localStorage.setItem("role", JSON.stringify(b.result.data.role_name));
          localStorage.setItem("user_name", b.result.data.Ad_username)
          localStorage.setItem("user-id", b.result.data._id)
          localStorage.setItem("current_role", b.result.data.role_name[1])
          // localStorage.setItem("role-name", b.result.data.role_name[1])
            setShowLoading(false)
            navigate(`/admin/dashboard`);
            window.location.reload()

            toast.success(b.info.msg);

            }
            // toast.success(b.info.msg )
          })
      }else{
        toast.error("Password doesn't match")
      }
      
    }
    const togglePasswordVisibility = (type) => {
      if (type === "password") {
        setVisiblePassword(!visiblePassword);
      } else {
        setVisibleRePassword(!visibleRePassword);
      }
    };

      
    // const navigate_user = (user) => {
   
    //   // if (user.length === 1) {
        
    //     navigate(`/admin/dashboard`);
        
    //   // }
    // };

    
  return (
    <div>
       <div className="container-fluid">
      <div className="row">
      <div className="col-5 leftbanner d-flex align-items-center justify-content-cteenter">
          <div className="banner-main d-flex flex-column justify-content-center align-items-center">
            <img src={heading} alt="title" className="img-fluid w-50" />
            <p className="text-center w-75 mt-5 text-white">It is the ultimate solution for organizations looking to automate their Testcases, test suites and unlock the full potential of AI-driven testcases creation. With its intuitive interface, powerful capabilities, and unrivaled performance, Ignitho quality accelerator empowers businesses to deliver exceptional service.</p>
          </div>
        </div>
        <div className="col-7 rightBanner d-flex align-items-center justify-content-center">
      <Form className="w-50 form-main bg-white rounded" style={{height: showValidation? "600px":showError? "300px":"480px"}}> 
     <h5 className="forms-login text-center mt-4">Set your password</h5> 
     {showLoader && 
     <div role="status" style={{marginLeft:"38%"}}>
                  <TailSpin visible={true}
                    height="50"
                    width="50"
                    color="#0083BF"
                    ariaLabel="tail-spin-loading"
                    radius="1" />
                </div>
                }
                {showError &&
                    <div className="">
                      <div className="flex justify-center items-center mx-5">
                        <img className="h-44 w-44"
                        style={{marginLeft:"35%"}}
                          src={warningIcon}
                          alt="Error"
                        />
                      </div>
                      <br />
                      <div>
                        <p className="text-4xl text-center mb-4">
                          Sorry!!
                        </p>
                        <p className="px-5 text-center">{subHeading}</p>
                      </div>
                    </div> 
                  }
                <br />
      { !showError && <>
        <div>
                   { !showLoader && <div className='w-20 h-20 flex justify-center' style={{marginLeft:"38%"}}
                    >
                      <SuccessIcon />
                    </div>}
                        <p className="text-center px-5">{subHeading}</p>
                      </div>
                      
      <Form.Group className="mb-3 w-75 name-input ml-5">
                <Form.Label className="fw-bold fs-6">New password</Form.Label>
                <div className='custom_icon'>
                  <div className='input_icon'>
                <Form.Control size="sm" 
                type={visiblePassword ? "text" : "password"}
                placeholder="New Password" 
                value={newPassword} 
                onChange={(e)=>{
                  setNewPassword(e.target.value)
                  e.target.value.length > 0 && setShowValidation(true)
                  }}/>
                  </div>
                  <div className='input_eye' onClick={()=>togglePasswordVisibility('password')}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          togglePasswordVisibility('password');
                      }
                  }}
                    >
                  {visiblePassword ? <BsEye /> : <BsEyeSlash />}
                   </div>
                  </div>
              </Form.Group>
  <Form.Group className="mb-3 w-75 email-input ml-5">
                <Form.Label className="fw-bold">Confirm new password</Form.Label>
                <div className='custom_icon'>
                  <div className='input_icon'>
                <Form.Control size="sm" 
                type={visibleRePassword ? "text" : "password"}
                placeholder="Confirm new Password" 
                value={cnfmPassword} onChange={(e)=>setCnfmPassword(e.target.value)} />
                 </div>
                 <div className='input_eye' onClick={()=>togglePasswordVisibility()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        togglePasswordVisibility();
                    }
                }}
                  >
                   {visibleRePassword ? <BsEye /> : <BsEyeSlash />}
                   </div>
                  </div>
              </Form.Group>
              {showValidation &&
                            <div className="mx-5">
                            <PasswordChecklist
                rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                ]}
                minLength={8}
                value={newPassword}
                valueAgain={cnfmPassword}
                 onChange={(e) =>{setShowPass(e)}}        
                    />
                </div>}
              
  <button className="btn btn-register w-75 ml-5" disabled={!showPass} onClick={submitPassword}>{showLoading ?'...Loading':'Submit'}</button>
  </>}
</Form>
    </div>
    </div>
    </div>

    </div>
  )
}

export default FreemiumReset